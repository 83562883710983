const COLORS = {
  white: '#fff',
  black: '#000',
  brand: {
    light: {
      700: '#007694',
      550: '#007D9C',
      500: '#007581',
      400: '#005675',
      300: '#004D61',
      100: '#00425a',
    },
    dark: {
      700: '#00b9e8',
      500: '#88e3fa',
      300: '#e4e5e5',
    },
  },
  darkBlue: {
    500: '#0b008f',
    300: '#100d38',
  },
  gray: {
    700: '#fafafa',
    500: '#e4e5e5',
    400: '#9f9f9f',
    100: '#222',
  },
}

export default COLORS
