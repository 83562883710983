import {useLogger} from '@kensho/lumberjack'
import {Patch} from 'immer'
import {reverse} from 'lodash-es'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'

import usePatchTranscript from '../../../api/usePatchTranscript'
import {APIErrorResponse, AsyncStatus, ScribeError} from '../../../types/types'
import isPrimitive from '../../../utils/isPrimitive'
import parseError from '../../../utils/parseError'

const SAVE_DELAY_MS = 1000

export interface TranscriptEditQueue {
  push: (patches: Patch[]) => void
  status: AsyncStatus
}

/**
 * Keeps a buffer of transcript edit operations and flushes them to the server every SAVE_DELAY_MS
 */
export default function useTranscriptEditQueue(options: {
  onSuccess: () => void
  onError: (err: ScribeError) => void
  transcriptId?: string
  /* whether patches are flushed to the server */
  disabled?: boolean
}): TranscriptEditQueue {
  const {onSuccess, onError, transcriptId, disabled = false} = options
  const patchTranscript = usePatchTranscript()
  const log = useLogger()
  const queueRef = useRef<Patch[]>([])
  const errorCallbackRef = useRef(onError)
  const successCallbackRef = useRef(onSuccess)
  const [status, setStatus] = useState<AsyncStatus>('idle')
  const statusRef = useRef<AsyncStatus>(status)
  const setStatusAndRef = useCallback((newStatus: AsyncStatus) => {
    statusRef.current = newStatus
    setStatus(newStatus)
  }, [])

  useEffect(() => {
    errorCallbackRef.current = onError
    successCallbackRef.current = onSuccess
  }, [onError, onSuccess])

  useEffect(() => {
    if (!transcriptId) return undefined
    if (disabled) return undefined

    const flush = async (): Promise<void> => {
      if (queueRef.current.length === 0) return

      if (statusRef.current === 'pending') return

      const immerPatches = queueRef.current
      queueRef.current = []

      // Optimize the patches sent over the wire, by removing intermediary "replace" patches for the same path
      // WARNING - this optimization is only safe because of 2 guarantees:
      // 1) Immer restricts patches to a subset of what the JSONPatch spec allows
      //    which does not include the "move" or "copy" operations.
      // 2) The transcript object does not have any paths that could be either a primitive or non-primitive value

      // reverse so it's easier to keep the last patch
      reverse(immerPatches)
      const replacedPaths: Record<string, boolean> = {}
      const optimizedPatches = immerPatches.reduce<Patch[]>((acc, patch) => {
        const path = patch.path.join('/')

        if (replacedPaths[path]) return acc

        if (patch.op === 'replace' && !replacedPaths[path] && isPrimitive(patch.value)) {
          replacedPaths[path] = true
        }

        acc.push(patch)
        return acc
      }, [])
      // reverse the patches back so that they will be applied in the correct order
      reverse(optimizedPatches)

      try {
        setStatusAndRef('pending')
        const response = await patchTranscript(transcriptId, optimizedPatches)

        if (!response.ok) {
          let json
          try {
            json = await response.json()
          } catch (err) {
            throw response
          }
          throw json
        }

        setStatusAndRef('success')
        successCallbackRef.current?.()
      } catch (err) {
        setStatusAndRef('error')
        log.error('Failed to flush useTranscriptEditQueue', {
          error: JSON.stringify(err, Object.getOwnPropertyNames(err)),
          patches: JSON.stringify(optimizedPatches),
        })

        if (err instanceof Response) {
          errorCallbackRef.current?.(parseError(err))
          return
        }

        const {status: responseStatus, title, detail} = err as APIErrorResponse
        errorCallbackRef.current?.({
          type:
            (err as Error).message?.startsWith('409') || responseStatus === 409
              ? 'saveTranscriptConflict'
              : 'saveTranscriptError',
          status: responseStatus,
          title,
          detail,
        })
      }
    }

    // flush every SAVE_DELAY_MS
    const intervalId = window.setInterval(() => {
      flush()
    }, SAVE_DELAY_MS)

    return () => {
      window.clearInterval(intervalId)

      // flush on unmount
      flush()
    }
  }, [patchTranscript, transcriptId, log, disabled, setStatusAndRef])

  return useMemo(
    () => ({
      push: (patches: Patch[]): void => {
        queueRef.current.push(...patches)
      },
      status,
    }),
    [status],
  )
}
