import {useCallback} from 'react'
import {Patch} from 'immer'
import {snakeCase} from 'lodash-es'

import {API_ENDPOINT} from '../constants'
import snakeCaseDeep from '../utils/snakeCaseDeep'

import useAPIFetch from './useAPIFetch'

export interface JSONPatch {
  path: string
  op: 'replace' | 'remove' | 'add'
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  value?: any
}

export default function usePatchTranscript(): (
  id: string,
  patches: Patch[] | JSONPatch[],
) => Promise<Response> {
  const apiFetch = useAPIFetch(false)

  const patchTranscript = useCallback(
    (id: string, patches: Patch[] | JSONPatch[]) => {
      // three differences that we need to transform for patches stored on the client vs what the server expects:
      // 1. path keys need to be converted to snake_case
      // 2. paths need to be converted to JSON pointer strings instead of arrays
      // 3. values need to be converted to snake_case
      const JSONPatches = patches.map((patch) =>
        snakeCaseDeep({
          ...patch,
          path: Array.isArray(patch.path)
            ? `/${patch.path.map((part) => snakeCase(`${part}`)).join('/')}`
            : patch.path,
        }),
      ) as JSONPatch[]

      return apiFetch.execute(`${API_ENDPOINT}/transcription/${id}`, {
        method: 'PATCH',
        body: JSON.stringify(JSONPatches),
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
      })
    },

    [apiFetch],
  )

  return patchTranscript
}
