import {useRef, useEffect, useCallback} from 'react'
import {IconCircleCheck, IconExclamation, IconInfo, IconX} from '@kensho/icons'
import {css} from '@emotion/react'

import Button from '../controls/Button'
import {IconComponent, Intent} from '../types'
import {useTheme} from '../colors/ThemeProvider'
import getBackgroundColor from '../colors/getBackgroundColor'
import getTextColor from '../colors/getTextColor'
import {BOX_SHADOW, WHITE} from '../constants/theme'

function getIcon(intent?: Intent): IconComponent {
  switch (intent) {
    case 'primary':
      return IconCircleCheck
    case 'danger':
      return IconExclamation
    default:
      return IconInfo
  }
}

function useTimeout(timeout: number, callback: () => void): [() => void, () => void, () => void] {
  const savedCallback = useRef(callback)
  const timer = useRef<number | null>(null)

  // store the callback in a ref so that it can change without restarting the timer
  useEffect(() => {
    savedCallback.current = callback
  })

  const stop = useCallback((): void => {
    if (timer.current) {
      window.clearTimeout(timer.current)
      timer.current = null
    }
  }, [])

  const start = useCallback((): void => {
    stop()
    if (timeout > 0) timer.current = window.setTimeout(() => savedCallback.current(), timeout)
  }, [stop, timeout])

  const complete = useCallback((): void => {
    stop()
    savedCallback.current()
  }, [stop])

  // start the timeout on first mount, and stop on unmount
  useEffect(() => {
    start()
    return stop
  }, [start, stop])

  return [stop, start, complete]
}

export interface ToastProps {
  /**
   * If true, the Toast should announce itself to screen-readers immediately. Otherwise, waits until current content is finished being read.
   *
   * @default false
   */
  alert?: boolean

  /**
   * Space-separated list of classes to pass to the underlying element.
   */
  className?: string

  /**
   * Visual intent of the component.
   */
  intent?: Intent

  /**
   * Content to display in the body of the toast.
   */
  message?: React.ReactNode

  /**
   * Callback to invoke when the toast is dismissed, either by the user or the timeout.
   */
  onDismiss: () => void

  /**
   * Milliseconds to wait before automatically dismissing the toast.
   *
   * @default 6000
   */
  timeout?: number
}

/**
 * An ephemeral notice to the user, often serving as feedback for an action.
 *
 * @see ToasterProvider to contain Toasts and render them on top of the application.
 * @see useToaster to generate Toasts programmatically within a component.
 */
export default function Toast(props: ToastProps): React.ReactNode {
  const {alert = false, className, intent, message, onDismiss, timeout = 6000} = props
  const theme = useTheme()
  const textColor = getTextColor({theme, intent})
  const Icon = getIcon(intent)

  const [stopTimeout, startTimeout, completeTimeout] = useTimeout(timeout, onDismiss)

  const cssToast = css`
    position: relative;
    display: flex;
    align-items: center;
    min-width: 352px;
    max-width: 411px;
    padding: 21px 22px;
    background: ${getBackgroundColor({theme, intent, baseBackgroundColor: WHITE[theme]})};
    box-shadow: ${BOX_SHADOW[theme]};
    color: ${textColor};
  `
  const cssToastMessage = css`
    font-size: 14px;
    line-height: 18px;
    flex: auto;
    overflow-wrap: break-word;
    max-width: 218px;
  `

  const cssButtonIcon = css`
    position: absolute;
    right: 10px;
    svg {
      color: ${textColor};
    }
  `

  const cssIcon = css`
    margin-right: 12px;
    color: ${textColor};
  `

  return (
    <div
      css={cssToast}
      className={className}
      onMouseEnter={stopTimeout}
      onMouseLeave={startTimeout}
      role={alert ? 'alert' : 'status'}
    >
      <Icon css={cssIcon} size={20} />
      <span css={cssToastMessage}>{message}</span>
      <Button
        aria-hidden="true"
        css={cssButtonIcon}
        icon={IconX}
        minimal
        onClick={completeTimeout}
      />
    </div>
  )
}
