import {useEffect, useState} from 'react'
import {Select} from '@kensho/neo'

import closeMediaStream from '../utils/closeMediaStream'
import {FormState} from '../core/transcription/transcriptionConfig/FormState'

interface AudioInputSelectProps {
  value?: string
  setAudioInputDeviceReady: React.Dispatch<React.SetStateAction<boolean>>
  onChange: (nextAudioDeviceInfoId?: string) => void
  setFormState: React.Dispatch<React.SetStateAction<FormState>>
  formError: FormState['errors']['audioInputDevice']
}

const microphoneAccessCache: Record<string, boolean> = {}

export default function AudioInputDeviceSelect(props: AudioInputSelectProps): React.ReactNode {
  const {value = '', setAudioInputDeviceReady, onChange, setFormState, formError} = props
  const [error, setError] = useState(false)
  const [audioInputDevices, setAudioInputDevices] = useState<MediaDeviceInfo[]>()

  // request permissions for audio input device(s) access
  useEffect(() => {
    if (value in microphoneAccessCache) {
      setAudioInputDeviceReady(microphoneAccessCache[value])
      return undefined
    }

    let current = true
    setAudioInputDeviceReady(false)
    navigator.mediaDevices
      .getUserMedia({
        audio: value
          ? {deviceId: value}
          : // if there is not a specific device selected request generic permissions
            // so we can get the labels for enumerateDevices()
            true,
      })
      .then((stream) => {
        if (current) {
          // browser might prompt user to choose a specific device rather than
          // general microphone access. If they choose a different device sync it
          const deviceId = stream.getAudioTracks()?.[0]?.getSettings()?.deviceId
          if (deviceId) {
            if (value !== deviceId) onChange(deviceId)
            microphoneAccessCache[deviceId] = true
            setAudioInputDeviceReady(true)
          }
        }
        // close the stream immediately, we just wanted to get permissions
        closeMediaStream(stream)
      })
      .catch(() => {
        if (current) {
          setError(true)
          setAudioInputDeviceReady(false)
        }
        if (value) microphoneAccessCache[value] = false
      })

    return () => {
      current = false
    }
  }, [value, onChange, setAudioInputDeviceReady])

  // get audio input devices
  useEffect(() => {
    if (audioInputDevices?.some((device) => device.label)) return undefined

    let current = true
    navigator.mediaDevices
      .enumerateDevices()
      .then((mediaDevices) => {
        if (current) {
          const nextAudioInputDevices = mediaDevices.filter(
            (mediaDevice) => mediaDevice.kind === 'audioinput',
          )
          setAudioInputDevices(nextAudioInputDevices)
        }
      })
      .catch(() => {
        if (current) setAudioInputDeviceReady(true)
      })

    return () => {
      current = false
    }
  }, [onChange, value, audioInputDevices, setAudioInputDeviceReady])

  const disabled = error || !audioInputDevices || audioInputDevices.length === 0
  const microphoneNotEnabledError = error
    ? 'Your microphone is not enabled. Please enable microphone in your browser settings.'
    : false
  const noMicrophoneDetectedError =
    audioInputDevices?.length === 0 ? 'No microphone detected. Please connect a microphone.' : false
  const displayedError = microphoneNotEnabledError || noMicrophoneDetectedError || disabled

  useEffect(() => {
    setFormState((prev) => ({...prev, errors: {...prev.errors, audioInputDevice: displayedError}}))
  }, [displayedError, setFormState])
  return (
    <Select
      disabled={disabled}
      isInvalid={!!formError}
      errorText={typeof formError === 'string' ? formError : undefined}
      label="Microphone setting"
      onChange={(nextValue) => onChange(nextValue || undefined)}
      options={
        audioInputDevices
          ? audioInputDevices.map((device, i) => ({
              label: device.label || `Microphone ${i + 1}`,
              value: device.deviceId,
            }))
          : []
      }
      value={value}
    />
  )
}
