import {useState, useLayoutEffect} from 'react'
import {createPortal} from 'react-dom'

import ClearGroup from '../controls/ClearGroup'

export interface PortalProps {
  /** Contents of the portal. */
  children: React.ReactNode
  /** Whether to add position styles to portal element */
  position?: boolean
}

const isBrowserEnv = typeof document === 'object'

/** Creates and returns a child element of the root body node. */
function useTopLevelElement(position = true): HTMLDivElement | null {
  const [element, setElement] = useState<HTMLDivElement | null>(null)

  useLayoutEffect(() => {
    if (isBrowserEnv) {
      const el = document.createElement('div')

      if (position) {
        el.style.position = 'absolute'
        el.style.top = '0'
        el.style.left = '0'
        el.style.right = '0'
      }
      document.body.appendChild(el)
      setElement(el)
      return () => {
        document.body.removeChild(el)
      }
    }
    return undefined
  }, [position])

  return element
}

/**
 * Renders content in a child of the root body DOM node.
 */
export default function Portal(props: PortalProps): React.ReactNode {
  const {children, position = true} = props

  const containerElement = useTopLevelElement(position)
  if (!containerElement) return null
  return <ClearGroup>{createPortal(children, containerElement)}</ClearGroup>
}
