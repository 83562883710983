import {useCallback, useState} from 'react'
import * as React from 'react'
import {SerializedStyles} from '@emotion/react'

import Popover from '../Popover'
import Menu, {MenuProps} from '../ui/menu/Menu'
import {useRegisterMultiClickOutsideElement} from '../../providers/MultiClickOutsideContext'
import {SiblingMenuProvider, useSiblingMenuContext} from '../../providers/SiblingMenuContext'
import MenuItem, {MenuItemProps} from '../ui/menu/MenuItem'

import popoverPaddingResetCss from './shared'

interface CascadingSubMenuProps {
  children: React.ReactNode
  menuCss?: SerializedStyles
  menuItemCss?: SerializedStyles
  menuItemProps: MenuItemProps
  menuProps?: MenuProps
}

export default function CascadingSubMenu({
  children,
  menuCss,
  menuProps = {},
  menuItemCss,
  menuItemProps,
}: CascadingSubMenuProps): React.ReactNode {
  const clickOutsideRef = useRegisterMultiClickOutsideElement<HTMLUListElement>()
  const [isOpen, setIsOpen] = useState(false)
  const closeSelf = useCallback(() => setIsOpen(false), [])
  const {closeAllMenus} = useSiblingMenuContext(closeSelf)
  const {onClick: menuItemOnClick, ...menuItemRest} = menuItemProps

  function toggleMenu(): void {
    if (isOpen) {
      setIsOpen(false)
    } else {
      closeAllMenus()
      setIsOpen(true)
    }
  }
  return (
    <Popover
      css={popoverPaddingResetCss}
      isOpen={isOpen}
      target={
        <MenuItem
          {...menuItemRest}
          onClick={(event) => {
            if (menuItemOnClick) {
              menuItemOnClick(event)
            }
            toggleMenu()
          }}
          css={menuItemCss}
        />
      }
      popperPlacement="right-start"
    >
      <SiblingMenuProvider>
        <Menu ref={clickOutsideRef} css={menuCss} {...menuProps}>
          {children}
        </Menu>
      </SiblingMenuProvider>
    </Popover>
  )
}
