import {css} from '@emotion/react'
import {IconEllipsis, IconTriangleRight} from '@kensho/icons'
import {useContext, useState, useCallback} from 'react'
import clsx from 'clsx'

import CascadingMenuProvider from '../../../components/CascadingMenu/CascadingMenuProvider'
import CascadingSubMenu from '../../../components/CascadingMenu/CascadingSubMenu'
import CascadingMenu from '../../../components/CascadingMenu/CascadingMenu'
import MenuItem from '../../../components/ui/menu/MenuItem'
import {
  useRegisterMultiClickOutsideCallback,
  useRegisterMultiClickOutsideElement,
} from '../../../providers/MultiClickOutsideContext'
import {ScribeError, TranscriptFormat, TranscriptMetadata} from '../../../types/types'
import useDownloadTranscript from '../../../hooks/useDownloadTranscript'
import parseError, {ErrorInput} from '../../../utils/parseError'
import ErrorDialog from '../../../components/ErrorDialog'
import SiteAnalyticsContext from '../../../providers/SiteAnalyticsContext'

import DeleteDialog from './DeleteDialog'
import {useTranscriptNameContext} from './TranscriptName/TranscriptNameContext'

const spawnMenuButtonCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 40px;
  height: 40px;
  background: none;
  border: 0;
  &:hover,
  &:focus {
    background: rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
`

const menuItemCss = css`
  height: 40px;
  font-size: 16px;
`

const actionMenuCss = css`
  width: 144px;
  border: 0;
  padding: 0;
`
const downloadMenuItemCss = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const downloadMenuCss = css`
  width: 92px;
  border: 0;
  padding: 0;
`

const downloadOptions: {key: TranscriptFormat; name: string}[] = [
  {key: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', name: 'DOCX'},
  {key: 'text/plain', name: 'TXT'},
  {key: 'text/vtt', name: 'VTT'},
  {key: 'application/json', name: 'JSON'},
]

interface ContextMenuProps {
  transcript: TranscriptMetadata
  deleteTranscript: (transcript: TranscriptMetadata) => Promise<void>
}

function InnerContextMenu({transcript, deleteTranscript}: ContextMenuProps): React.ReactNode {
  const {setIsEditing} = useTranscriptNameContext(transcript.id)
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  useRegisterMultiClickOutsideCallback(useCallback(() => setOpenMenu(false), [setOpenMenu]))
  const clickOutsideRef = useRegisterMultiClickOutsideElement<HTMLButtonElement>()
  const [, downloadTranscript] = useDownloadTranscript()
  const [downloadError, setDownloadError] = useState<ScribeError | null>(null)

  const analytics = useContext(SiteAnalyticsContext)

  return (
    <div
      // The parent row element has a click handler that opens the transcript view.
      // This prevents that from firing so the user can interact with this element.
      onClick={(event) => {
        event.stopPropagation()
      }}
      onKeyDown={(event) => {
        event.stopPropagation()
      }}
    >
      <DeleteDialog
        isOpen={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        transcript={transcript}
        deleteTranscript={deleteTranscript}
      />
      {downloadError && (
        <ErrorDialog isOpen onClose={() => setDownloadError(null)} error={downloadError} />
      )}
      <CascadingMenu
        isOpen={openMenu}
        css={actionMenuCss}
        target={
          <button
            aria-label="Actions"
            type="button"
            ref={clickOutsideRef}
            onClick={(event) => {
              event.stopPropagation()
              setOpenMenu((prev) => !prev)
            }}
            css={[
              spawnMenuButtonCss,
              css`
                background: ${openMenu ? 'rgba(0, 0, 0, 0.15)' : ''};
              `,
            ]}
          >
            <IconEllipsis
              className={clsx(
                'opacity-0 group-hover/table-row:opacity-100',
                openMenu ? 'opacity-100' : 'opacity-0',
              )}
            />
          </button>
        }
      >
        <CascadingSubMenu
          menuCss={downloadMenuCss}
          menuItemCss={menuItemCss}
          menuItemProps={{
            text: (
              <div css={downloadMenuItemCss}>
                Download
                <IconTriangleRight size={20} />
              </div>
            ),
          }}
        >
          {downloadOptions.map(({key, name}) => (
            <MenuItem
              key={key}
              css={menuItemCss}
              text={name}
              onClick={async () => {
                setOpenMenu(false)
                try {
                  await downloadTranscript(transcript.id, key, {transcriptMeta: transcript})
                  analytics.sendEvent('download', {file_type: name})
                } catch (error) {
                  setDownloadError(parseError(error as ErrorInput))
                }
              }}
            />
          ))}
        </CascadingSubMenu>
        <MenuItem
          css={menuItemCss}
          text="Rename"
          onClick={() => {
            setOpenMenu(false)
            setIsEditing(true)
          }}
        />
        <MenuItem
          css={menuItemCss}
          text="Delete"
          onClick={() => {
            setOpenMenu(false)
            setOpenDeleteDialog(true)
          }}
        />
      </CascadingMenu>
    </div>
  )
}

export default function ContextMenu(props: ContextMenuProps): React.ReactNode {
  return (
    <CascadingMenuProvider>
      <InnerContextMenu {...props} />
    </CascadingMenuProvider>
  )
}
