import {ClassNames} from '@emotion/react'
import {CSSTransition} from 'react-transition-group'

interface FadeInOutProps {
  children: React.ReactNode
  in?: boolean
  duration?: number // milliseconds
  delay?: number // milliseconds
}

export default function FadeInOut({
  children,
  in: inProp,
  duration = 500,
  delay = 3000,
}: FadeInOutProps): React.ReactNode {
  return (
    <ClassNames>
      {({css}) => (
        <CSSTransition
          in={inProp === undefined ? true : inProp}
          mountOnEnter
          unmountOnExit
          classNames={{
            enter: css`
              opacity: 0;
            `,
            enterActive: css`
              opacity: 1;
              transition: opacity ${delay}ms ease ${duration}ms;
            `,
            exit: css`
              opacity: 1;
            `,
            exitActive: css`
              opacity: 0;
              transition: opacity ease ${duration}ms;
            `,
          }}
          timeout={{
            enter: delay + duration,
            exit: duration,
          }}
        >
          {children}
        </CSSTransition>
      )}
    </ClassNames>
  )
}
