import * as React from 'react'

import {MultiClickOutsideContextProvider} from '../../providers/MultiClickOutsideContext'

interface CascadingMenuProviderProps {
  children: React.ReactNode
}

export default function CascadingMenuProvider({
  children,
}: CascadingMenuProviderProps): React.ReactNode {
  return <MultiClickOutsideContextProvider>{children}</MultiClickOutsideContextProvider>
}
