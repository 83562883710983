import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useReducer,
} from 'react'

import assertNever from '../utils/assertNever'
import mod from '../utils/mod'

export interface RoverItem {
  element: HTMLElement
  index: number
}

interface RoverState {
  items: RoverItem[]
  activeItem: RoverItem | null
}

type RoverAction =
  | {
      type: 'setActiveItem'
      item: RoverItem
    }
  | {
      type: 'setItems'
      items: RoverItem[]
    }
  | {
      type: 'registerItem'
      element: HTMLElement
    }
  | {
      type: 'unregisterItem'
      element: HTMLElement
    }

function getNextActiveItem(activeItem: RoverItem | null, items: RoverItem[]): RoverItem | null {
  const {length} = items
  if (activeItem === null) {
    if (length > 0) {
      // Initial active item should be first item
      return items[0]
    }
  } else if (length === 0) {
    return null
  } else if (!items.includes(activeItem)) {
    const maybeSameItem = items.find((item) => item.element === activeItem.element)
    if (maybeSameItem) {
      // If the list has changed but the item is still in the list, keep focus on the item.
      return maybeSameItem
    }
    // Otherwise, focus the item closest to where the previous focus was.
    const nextIndex = Math.max(Math.min(activeItem.index, length - 1), 0)
    return items[nextIndex]
  }
  return activeItem
}

function registerItem(element: HTMLElement, prevItems: RoverItem[]): RoverItem[] {
  // If there are no items, just register at index 0.
  if (prevItems.length === 0) {
    return [{element, index: 0}]
  }
  // If it's already registered, don't do anything.
  if (prevItems.find((item) => item.element === element)) {
    return prevItems
  }

  const index = prevItems.findIndex(
    (item) => item.element.compareDocumentPosition(element) === Node.DOCUMENT_POSITION_PRECEDING,
  )

  const newItem = {element, index}
  let nextItems

  if (index === -1) {
    nextItems = [...prevItems, newItem]
  } else {
    nextItems = [...prevItems.slice(0, index), newItem, ...prevItems.slice(index)]
  }

  return nextItems.map((item, currentIndex) => ({
    ...item,
    index: currentIndex,
  }))
}

function roverReducer(state: RoverState, action: RoverAction): RoverState {
  const {activeItem, items} = state
  switch (action.type) {
    case 'setActiveItem':
      return {...state, activeItem: action.item}
    case 'setItems': {
      const nextItems = action.items
      const nextActiveItem = getNextActiveItem(activeItem, nextItems)
      return {...state, activeItem: nextActiveItem, items: nextItems}
    }
    case 'registerItem': {
      const {element} = action
      const nextItems = registerItem(element, items)
      const nextActiveItem = getNextActiveItem(activeItem, nextItems)
      return {...state, activeItem: nextActiveItem, items: nextItems}
    }
    case 'unregisterItem': {
      const {element} = action
      const nextItems: RoverItem[] = items
        .filter((item) => item.element !== element)
        .map((item, index) => ({...item, index}))
      const nextActiveItem = getNextActiveItem(activeItem, nextItems)
      return {...state, activeItem: nextActiveItem, items: nextItems}
    }
    default:
      return assertNever(action)
  }
}

interface RoverControls extends Omit<RoverProps, 'children'> {
  activateNext(): RoverItem | null
  activatePrevious(): RoverItem | null
}

/**
 * Sets up the initial state that should be passed as props into `Rover`.
 *
 * @returns State and dispatch for Rover state, helper functions to activate the next and previous items.
 */
export function useRoverInitialization(): RoverControls {
  const [state, dispatch] = useReducer(roverReducer, {items: [], activeItem: null})
  const {activeItem, items} = state

  const activateNext = useCallback(() => {
    if (!activeItem) return null
    const nextIndex = mod(activeItem.index + 1, items.length)
    dispatch({type: 'setActiveItem', item: items[nextIndex]})
    return items[nextIndex]
  }, [activeItem, items])

  const activatePrevious = useCallback(() => {
    if (!activeItem) return null
    const nextIndex = mod(activeItem.index - 1, items.length)
    dispatch({type: 'setActiveItem', item: items[nextIndex]})
    return items[nextIndex]
  }, [activeItem, items])

  return {state, dispatch, activateNext, activatePrevious}
}

interface RoverContextProps {
  state: RoverState
  dispatch: React.Dispatch<RoverAction>
}

const IndexContext = createContext<RoverContextProps | null>(null)

interface RoverProps {
  children: ReactNode
  dispatch: React.Dispatch<RoverAction>
  state: RoverState
}

/**
 * Provides functionality for a roving tabindex across a list of items.
 */
export default function Rover(props: RoverProps): React.ReactNode {
  const {children, dispatch, state} = props

  const contextValue = useMemo(() => ({state, dispatch}), [state, dispatch])

  return <IndexContext.Provider value={contextValue}>{children}</IndexContext.Provider>
}

interface RoverItemControls {
  active: boolean
  activateItem: () => void
}

/**
 * Registers an element as a RoverItem in the RoverContext.
 *
 * @param element The element to register.
 * @returns Whether the item is active, and a function to set this item to be active.
 */
export function useRoverItem(element: HTMLElement | null, disabled?: boolean): RoverItemControls {
  const context = useContext(IndexContext)
  if (context === null) {
    throw new Error('Cannot call `useRoverItem` outside a `Rover` component!')
  }
  const {
    state: {items, activeItem},
    dispatch,
  } = context

  // Initially null
  const item = items.find((currentItem) => currentItem.element === element) || null

  useLayoutEffect(() => {
    if (element === null || disabled) return undefined
    dispatch({type: 'registerItem', element})

    return () => dispatch({type: 'unregisterItem', element})
  }, [disabled, dispatch, element])

  const active = item === activeItem
  const activateItem = useCallback(
    () => item && dispatch({type: 'setActiveItem', item}),
    [dispatch, item],
  )

  return {active, activateItem}
}
