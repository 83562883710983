import {useLogger} from '@kensho/lumberjack'
import {Button, Icon, InputGroup} from '@kensho/neo'
import clsx from 'clsx'
import {DebouncedFunc, debounce, sortBy} from 'lodash-es'
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import FocusLock from 'react-focus-lock'

import Popover from '../../../components/Popover'
import useClickOutside from '../../../hooks/useClickOutside'
import TranscriptContext from '../../../providers/TranscriptContext'
import {APITranscriptSlice, SpeakerInfo} from '../../../types/types'
import getSpeakerColor from '../../../utils/getSpeakerColor'
import {produceOperation} from '../../../utils/transcriptPatchUtils'
import {TranscriptPermissionsContext} from '../TranscriptPermissionsProvider'

import StopInputPropagation from './StopInputPropagation'
import {useDispatchEditOperation} from './DispatchEditOperationProvider'

interface SpeakerLabelProps {
  slice: APITranscriptSlice
  sliceIndex: number
  speakerId: number
}

/**
 * Will match if all the characters of matchText are included in text in the correct order,
 * even if the characters are discontiguous
 *
 * If a match, returns a score based on how well it matched, a lower score is better
 * If not a match, returns -1
 *
 * @example orderedMatch('John Smith', 'j') // 0
 * @example orderedMatch('John Smith', 'js') // 1
 * @example orderedMatch('John Smith', 'smith') // 5
 * @example orderedMatch('John Smith', 'jim') // -1
 */
function orderedMatch(text: string, matchText: string): number {
  if (matchText.length > text.length) return -1
  if (!matchText.trim()) return -1

  /* eslint-disable no-param-reassign */
  text = text.toLowerCase()
  matchText = matchText.toLowerCase()
  /* eslint-enable no-param-reassign */

  let firstMatchingCharIndex = -1
  let largestSegmentLength = 0
  let currentSegmentLength = 0
  let textIndex = 0
  let matchTextIndex = 0

  for (matchTextIndex; matchTextIndex < matchText.length; matchTextIndex += 1) {
    currentSegmentLength += 1

    // continue consuming text characters until we find a match
    while (matchText[matchTextIndex] !== text[textIndex]) {
      largestSegmentLength = Math.max(largestSegmentLength, currentSegmentLength)
      currentSegmentLength = 0
      textIndex += 1
      // ran out of text to consume
      if (textIndex >= text.length) return -1
    }

    if (firstMatchingCharIndex === -1) firstMatchingCharIndex = textIndex
    largestSegmentLength = Math.max(largestSegmentLength, currentSegmentLength)
  }

  return (
    matchText.length -
    largestSegmentLength + // prefer matches that are more contiguous
    firstMatchingCharIndex + // prefer matches that start earlier in the string
    (largestSegmentLength === matchText.length ? 0 : matchText.length) // heavily prefer matches that consume the entire matchText contiguously
  )
}

export default function SpeakerLabel(props: SpeakerLabelProps): React.ReactNode {
  const {slice, sliceIndex, speakerId} = props
  const {dispatchEditOperation} = useDispatchEditOperation()
  const {transcript} = useContext(TranscriptContext)
  const {transcriptPermissions} = useContext(TranscriptPermissionsContext)
  const [focusedIndex, setFocusedIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [isAddingSpeaker, setIsAddingSpeaker] = useState(false)
  const listRef = useRef<HTMLUListElement>(null)
  const [filter, setFilter] = useState('')
  const clearFilterRef = useRef<DebouncedFunc<() => void>>()
  const log = useLogger()

  const [draftSpeakerName, setDraftSpeakerName] = useState('')

  function closePopover(): void {
    setIsOpen(false)
  }
  function togglePopover(): void {
    setIsOpen((prev) => !prev)
  }

  const clickOutsideRef = useClickOutside(closePopover)

  const changeSpeaker = useCallback(
    (nextSpeakerId: number) => {
      if (!transcript) return
      if (!transcriptPermissions.edit) return

      const operation = produceOperation({
        action: {type: 'change-slice-speaker', sliceIndex, speakerId: nextSpeakerId},
        transcript,
        transcriptSelection: null,
        log,
      })
      dispatchEditOperation(operation)
    },
    [transcript, transcriptPermissions, dispatchEditOperation, sliceIndex, log],
  )

  const addSpeaker = useCallback(() => {
    if (!transcript) return
    if (!transcriptPermissions.edit) return
    if (!draftSpeakerName.trim()) return

    const operation = produceOperation({
      action: {
        type: 'change-slice-speaker',
        data: {name: draftSpeakerName.trim()},
        speakerId: null,
        sliceIndex,
      },
      transcript,
      transcriptSelection: null,
      log,
    })
    dispatchEditOperation(operation)

    setIsAddingSpeaker(false)
    setDraftSpeakerName('')
  }, [transcript, transcriptPermissions, draftSpeakerName, dispatchEditOperation, sliceIndex, log])

  const speakers = useMemo<[id: number, speakerInfo: SpeakerInfo][]>(() => {
    if (!transcript?.speakers) return []
    return sortBy(Object.entries(transcript.speakers), ([, speakerInfo]) => speakerInfo.name)
      .filter(([id]) => Number(id) !== -1)
      .map(([id, speakerInfo]) => [Number(id), speakerInfo])
  }, [transcript?.speakers])

  // after the user stops typing, clear the accumulated text filter
  const clearFilter = useMemo(() => {
    const debouncedFunc = debounce(() => setFilter(''), 2000)

    clearFilterRef.current = debouncedFunc

    return debouncedFunc
  }, [])

  useEffect(
    () => () => {
      clearFilterRef.current?.cancel()
    },
    [],
  )

  // speaker list might have overflow, so keep focused index in view
  useEffect(() => {
    listRef.current?.children[focusedIndex]?.scrollIntoView({block: 'nearest'})
  }, [focusedIndex])

  useEffect(() => {
    if (isOpen) {
      setFocusedIndex(0)
    } else {
      setIsAddingSpeaker(false)
      setDraftSpeakerName('')
    }
  }, [isOpen])

  if (!transcript) return null

  return (
    <div className="flex w-full items-center overflow-hidden p-1 pl-0">
      <Popover
        isOpen={isOpen}
        popperPlacement="bottom-start"
        target={
          <button
            type="button"
            data-testid="transcript-speaker"
            className="mr-2 min-w-5 cursor-pointer select-none overflow-hidden text-ellipsis whitespace-nowrap rounded-md bg-black/0 pr-1 text-left font-semibold outline outline-2 outline-offset-2 outline-transparent ring-1 ring-inset ring-transparent focus-visible:z-10 focus-visible:outline-cyan-600 enabled:hover:bg-black/[0.03] enabled:active:bg-black/[0.06] disabled:cursor-default disabled:text-gray-400 disabled:ring-transparent"
            style={{color: getSpeakerColor(slice.speakerId)}}
            title={transcript.speakers[speakerId].name}
            onClick={() => togglePopover()}
            onKeyDown={(event) => {
              // prevent keypresses from propagating to transcript which uses them to edit the transcript
              event.stopPropagation()
            }}
          >
            {transcript.speakers[speakerId].name}
          </button>
        }
      >
        <StopInputPropagation>
          {/* TODO: remove this FocusLock dep after this is moved to @kensho/neo#Popover */}
          <FocusLock returnFocus>
            <div
              ref={clickOutsideRef}
              className="w-80"
              onKeyDown={(event) => {
                if (!isOpen) return

                if (event.key === 'Enter') {
                  if (!transcriptPermissions.edit) return
                  changeSpeaker(speakers[focusedIndex][0])
                } else if (event.key === 'Escape') {
                  setIsOpen(false)
                } else if (event.key === 'ArrowDown' && !isAddingSpeaker) {
                  const nextFocusedIndex = Math.min(speakers.length - 1, focusedIndex + 1)
                  setFocusedIndex(nextFocusedIndex)
                } else if (event.key === 'ArrowUp' && !isAddingSpeaker) {
                  const nextFocusedIndex = Math.max(0, focusedIndex - 1)
                  setFocusedIndex(nextFocusedIndex)
                } else if (event.key === ' ') {
                  // don't allow default of closing the popover, since they may be filtering speakers
                  event.preventDefault()
                } else if (/[a-zA-Z0-9-.]/.test(event.key) || event.key === ' ') {
                  const nextFilter = filter + event.key
                  setFilter(nextFilter)
                  clearFilter()
                  const matches = sortBy(
                    speakers
                      .map(([, speaker], i) => [i, orderedMatch(speaker.name, nextFilter)])
                      .filter(([, matchScore]) => matchScore !== -1),
                    ([, matchScore]) => matchScore,
                  )

                  if (matches.length) {
                    setFocusedIndex(matches[0][0])
                  }
                }
              }}
            >
              <ul ref={listRef} className="max-h-72 overflow-auto">
                {speakers.map(([id, speaker], i) => (
                  <li
                    key={id}
                    className={clsx(
                      'border-l-4 hover:bg-black/[0.03]',
                      focusedIndex === i ? 'border-cyan-500 bg-black/[0.03]' : 'border-transparent',
                    )}
                  >
                    <button
                      className="focus:ouline-none flex h-10 w-full cursor-pointer items-center gap-2 overflow-hidden pl-4 focus-visible:outline-none"
                      type="button"
                      title={speaker.name}
                      onKeyDown={(event) => {
                        if (!transcriptPermissions.edit) return

                        if (event.key === 'Enter') {
                          changeSpeaker(id)
                          setIsOpen(false)
                        }
                      }}
                      onClick={() => {
                        if (!transcriptPermissions.edit) return

                        changeSpeaker(id)
                        setIsOpen(false)
                      }}
                    >
                      <div
                        className={clsx(
                          'flex items-center justify-center',
                          !isAddingSpeaker && speakerId === id ? 'opacity-100' : 'opacity-0',
                        )}
                      >
                        <Icon icon="CheckIcon" size="small" />
                      </div>
                      <div
                        className="h-3 w-3 flex-none rounded-full"
                        style={{backgroundColor: getSpeakerColor(id)}}
                      />
                      <span className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                        {speaker.name}
                      </span>
                    </button>
                  </li>
                ))}
              </ul>

              {isAddingSpeaker && (
                <div className="flex h-10 w-full items-center gap-2 border-l-4 border-transparent pl-4">
                  <div className="flex items-center justify-center opacity-100">
                    <Icon icon="CheckIcon" size="small" />
                  </div>
                  <div className="h-3 w-3 flex-none rounded-full bg-slate-600" />
                  <div
                    className="mr-4 flex-1"
                    onKeyDown={(event) => {
                      // prevent keypresses from propagating to container which uses them to filter+focus speakers
                      event.stopPropagation()

                      // TODO: move this to InputGroup when onKeyDown handler is supported
                      if (event.key === 'Enter') {
                        if (draftSpeakerName.trim() === '') return
                        if (!transcriptPermissions.edit) return
                        addSpeaker()
                        setIsOpen(false)
                      } else if (event.key === 'Escape') {
                        setIsAddingSpeaker(false)
                        setDraftSpeakerName('')
                      }
                    }}
                  >
                    <InputGroup
                      autoFocus
                      value={draftSpeakerName}
                      onChange={(event) => setDraftSpeakerName(event.target.value)}
                    />
                  </div>
                </div>
              )}

              {transcriptPermissions.edit && (
                <div className="mt-3 border-t-[1px] border-gray-300">
                  <Button
                    icon="PlusCircleIcon"
                    minimal
                    onClick={() => {
                      setDraftSpeakerName('')
                      setIsAddingSpeaker(true)
                      setFocusedIndex(-1)
                    }}
                  >
                    Add a new speaker
                  </Button>
                </div>
              )}
            </div>
          </FocusLock>
        </StopInputPropagation>
      </Popover>
    </div>
  )
}
