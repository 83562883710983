import {css} from '@emotion/react'
import {useLayoutEffect, useState} from 'react'

import Link from '../../components/Link'

interface EnableMicOverlayProps {
  audioInputDeviceReady: boolean
}

const overlayCss = css`
  background: rgba(0, 0, 0, 0.8);
  height: 50px;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`

const textCss = css`
  max-width: 570px;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  text-align: center;
`

const linkCss = css`
  color: inherit;
  text-decoration: underline;
  display: inline-block;
`

export default function EnableMicOverlay(props: EnableMicOverlayProps): React.ReactNode {
  const {audioInputDeviceReady} = props
  // hide until short delay to prevent flash before we retrieve audio input devices and status
  const [delayReached, setDelayReached] = useState(false)
  useLayoutEffect(() => {
    const transitionTimeout = window.setTimeout(() => setDelayReached(true), 500)
    return () => {
      window.clearTimeout(transitionTimeout)
    }
  }, [])

  if (audioInputDeviceReady || !delayReached) return null

  return (
    <div css={overlayCss}>
      <p css={textCss}>
        Please enable microphone access by selecting “Allow” in the browser prompt. Need help?{' '}
        <Link css={linkCss} to="https://docs.kensho.com/scribe/v2/faq">
          See the Scribe FAQ for troubleshooting tips.
        </Link>
      </p>
    </div>
  )
}
