import {css} from '@emotion/react'

import COLORS from './colors'

/* Typography */

export const h4Body2Css = css`
  font-size: 18px;
  line-height: 27px;
`

export const labelCss = css`
  font-size: 16px;
  line-height: 22px;
`

export const legalCss = css`
  font-size: 12px;
  line-height: 20px;
  margin: 0;
`

export const hBoldCss = css`
  font-weight: 700;
`

export const whiteTextCss = css`
  color: ${COLORS.white};
`

export const backgroundWhiteCss = css`
  background-color: ${COLORS.white};
`
