import {useRef, useEffect, useCallback} from 'react'

/**
 * Invokes a callback when a mousemove event occurs outside of the bounds of an element.
 *
 * @param callback The callback to invoke.
 * @returns A ref to attach to the desired element.
 */
export default function useClickOutside(
  callback: (event: MouseEvent) => void,
): (node: HTMLElement | null) => void {
  const elementRef = useRef<HTMLElement | null>(null)
  const callbackRef = useRef(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    function handleEvent(event: MouseEvent): void {
      const element = elementRef.current
      if (!element) return
      if (!(event.target instanceof Node)) return
      if (!element.contains(event.target)) callbackRef.current(event)
    }

    window.addEventListener('mousemove', handleEvent)
    return () => window.removeEventListener('mousemove', handleEvent)
  }, [])

  return useCallback((node) => {
    elementRef.current = node
  }, [])
}
