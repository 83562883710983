// sample rate scribe API expects
export const REALTIME_TRANSCRIPTION_SAMPLE_RATE = 16000
// sample rate used as the source of realtime audio data for waveform
export const REALTIME_NUM_CHANNELS = 1
export const REALTIME_RECORDING_MIME_TYPE = 'audio/webm'
export const REALTIME_SRC_MIME_TYPE = 'audio/wav'
export const UNKNOWN_SPEAKER_COLOR = '#999999'
export const VALID_AUDIO_FILE_EXTS = ['.aac', '.flac', '.m4a', '.mp3', '.mp4', '.wav']
// From Kensho design system light theme
// https://www.figma.com/file/uRi4O8iVvy1o65yNDcETuE/Kensho-Design-System?node-id=429%3A1165
// colors will be repeated if there are more speakers
export const SPEAKER_COLORS = [
  '#007a99',
  '#a70daf',
  '#392ad8',
  '#996800',
  '#c61936',
  '#01822c',
  '#00a1c9',
  '#f53bff',
  '#7266ff',
  '#c78800',
  '#eb4460',
  '#00ab39',
  '#005675',
  '#6e0274',
  '#0b008f',
  '#664600',
  '#910018',
  '#005c1f',
  // overflow colors, want to avoid using but here in case we do need in the future
  // and I don't want to type them all out again
  // '#2EBADF',
  // '#8c0693',
  // '#0b0274',
  // '#ffe4a3',
  // '#972034',
  // '#9eff81',
  // '#46cae6',
  // '#500054',
  // '#070054',
  // '#554217',
  // '#55181F',
  // '#32801b',
]
export const TIMELINE_HEIGHT = 35

// calculated by looking at 5 hours of transcripts
export const DURATION_PER_CHAR_MS = 35
export const DURATION_TOKEN_GAP_MS = 150
