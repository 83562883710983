import {Button, Tooltip} from '@kensho/neo'
import {useCallback, useEffect, useState} from 'react'

import useFootPedal from '../../../hooks/useFootPedal'

interface FootPedalProps {
  seekMedia: (options: {timeSeconds: number; play?: boolean; scroll?: boolean}) => void
  currentTime: number
  setPaused: React.Dispatch<React.SetStateAction<boolean>>
  hasMedia: boolean
}

export default function FootPedalButton(props: FootPedalProps): React.ReactNode {
  const {seekMedia, currentTime, setPaused, hasMedia} = props

  const [isWindowInFocus, setIsWindowInFocus] = useState(false)

  const {connectDevice, disconnectDevice, connected} = useFootPedal(
    () => seekMedia({timeSeconds: currentTime / 1000 + 3}),
    () => seekMedia({timeSeconds: currentTime / 1000 - 3}),
    () => {
      if (hasMedia) setPaused((prev) => !prev)
    },
    isWindowInFocus,
  )

  const handleFocus = useCallback((): void => {
    setIsWindowInFocus(true)
  }, [])

  const handleBlur = useCallback((): void => {
    setIsWindowInFocus(false)
  }, [])

  useEffect(() => {
    window.addEventListener('focus', handleFocus)
    window.addEventListener('blur', handleBlur)

    return () => {
      window.removeEventListener('focus', handleFocus)
      window.removeEventListener('blur', handleBlur)
    }
  }, [isWindowInFocus, handleFocus, handleBlur])

  return (
    <Tooltip content={connected ? 'Disconnect foot pedal' : 'Connect foot pedal'} position="bottom">
      <Button
        aria-label={connected ? 'Disconnect foot pedal' : 'Connect foot pedal'}
        icon="CursorArrowRippleIcon"
        intent={connected ? 'default' : 'primary'}
        onClick={
          connected
            ? () => {
                disconnectDevice()
              }
            : () => {
                connectDevice()
              }
        }
        minimal
      />
    </Tooltip>
  )
}
