import {useCallback, useEffect, useState} from 'react'

interface CustomStorageEventDetail {
  key: string
  id: string
}

/**
 * NOTE: that this must be a serializable value
 */
export default function useLocalStorageState<T>(
  key: string,
  defaultValue: T,
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [id] = useState(`${Math.random()}`)

  const [value, setValue] = useState<T>(() => {
    const storedValue = window.localStorage.getItem(key)
    if (storedValue) {
      const parsedValue = JSON.parse(storedValue)
      return parsedValue
    }

    if (defaultValue) {
      window.localStorage.setItem(key, JSON.stringify(defaultValue))
      return defaultValue
    }

    return undefined
  })

  const setLocalStorageValue = useCallback(
    (valueOrSetter: T | ((valueOrSetter: T) => T)): void => {
      let nextValue: T
      if (typeof valueOrSetter === 'function') {
        /* @ts-ignore-next-line */
        nextValue = valueOrSetter(value)
      } else {
        nextValue = valueOrSetter
      }
      window.localStorage.setItem(key, JSON.stringify(nextValue))
      setValue(nextValue)

      // broadcast change so that other call sites can update
      window.dispatchEvent(
        new CustomEvent<CustomStorageEventDetail>('customStorageChange', {
          detail: {
            key,
            id,
          },
        }),
      )
    },
    [key, id, value],
  )

  // Listen for changes to localStorage
  useEffect(() => {
    const onStorageChange = (e: CustomEvent<CustomStorageEventDetail>): void => {
      if (e.detail.key === key && e.detail.id !== id) {
        const item = window.localStorage.getItem(e.detail.key)
        setValue(item === null ? null : JSON.parse(item))
      }
    }

    window.addEventListener('customStorageChange', onStorageChange as EventListener)

    return () => {
      window.removeEventListener('customStorageChange', onStorageChange as EventListener)
    }
  }, [key, id])

  return [value, setLocalStorageValue]
}
