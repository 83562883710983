import {createContext, useState, useEffect, useContext, useRef, useMemo, useId} from 'react'

interface CascadingMenuType {
  setRefs: React.Dispatch<
    React.SetStateAction<{
      [key: string]: React.RefObject<HTMLElement>
    }>
  >
  setCallbacks: React.Dispatch<
    React.SetStateAction<{
      [key: string]: () => void
    }>
  >
}

export const MultiClickOutsideContext = createContext<CascadingMenuType | undefined>(undefined)
/**
 * Register elements with useRegisterMultiClickOutsideElement, register
 * callbacks with useRegisterMultiClickOutsideCallback When a click happens
 * outside of the registered elements,the registered callbacks will be invoked
 */
export function MultiClickOutsideContextProvider({
  children,
}: {
  children: React.ReactNode
}): React.ReactNode {
  const [refs, setRefs] = useState<{[key: string]: React.RefObject<HTMLElement>}>({})
  const [callbacks, setCallbacks] = useState<{[key: string]: () => void}>({})

  useEffect(() => {
    const handleClick = (e: MouseEvent): void => {
      const clickInsideAny = Object.values(refs).some((ref) => {
        if (!ref.current) return false
        if (!(e.target instanceof Node)) return false
        if (ref.current.contains(e.target)) return true
        return false
      })
      if (!clickInsideAny) {
        Object.values(callbacks).forEach((cb) => cb())
      }
    }
    document.addEventListener('mousedown', handleClick)
    return () => document.removeEventListener('mousedown', handleClick)
  }, [refs, callbacks])

  const value = useMemo(() => ({setRefs, setCallbacks}), [setRefs, setCallbacks])

  return (
    <MultiClickOutsideContext.Provider value={value}>{children}</MultiClickOutsideContext.Provider>
  )
}

export function useRegisterMultiClickOutsideElement<
  T extends HTMLElement,
>(): React.MutableRefObject<T | null> {
  const context = useContext(MultiClickOutsideContext)
  const id = useId()
  const ref = useRef<T | null>(null)
  if (context === undefined) {
    throw new Error(
      'useRegisterMultiClickOutsideElement must be used within a MultiClickOutsideContextProvider',
    )
  }
  const {setRefs} = context

  useEffect(() => {
    setRefs((refs) => ({...refs, [id]: ref}))
    return () =>
      setRefs((refs) => {
        const {[id]: _, ...rest} = refs
        return rest
      })
  }, [id, ref, setRefs])

  return ref
}

export function useRegisterMultiClickOutsideCallback(callback: () => void): void {
  const context = useContext(MultiClickOutsideContext)
  const id = useId()
  if (context === undefined) {
    throw new Error(
      'useRegisterMultiClickOutsideCallback must be used within a MultiClickOutsideContextProvider',
    )
  }
  const {setCallbacks} = context

  useEffect(() => {
    setCallbacks((callbacks) => ({...callbacks, [id]: callback}))
    return () =>
      setCallbacks((callbacks) => {
        const {[id]: _, ...rest} = callbacks
        return rest
      })
  }, [callback, id, setCallbacks])
}
