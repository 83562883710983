import {IconArrowDown, IconArrowUp} from '@kensho/icons'
import {Table, TableColumn} from '@kensho/neo'
import React, {Dispatch} from 'react'
import {css} from '@emotion/react'

import Skeleton from '../../../components/Skeleton'
import {TranscriptMetadata, TranscriptMetadataParams} from '../../../types/types'

import ContextMenu from './ContextMenu'
import JobStatus from './JobStatus'
import {TranscriptNameContextProvider} from './TranscriptName/TranscriptNameContext'
import TranscriptNameDesktop from './TranscriptName/TranscriptNameDesktop'
import {OptionsAction} from './types'
import prettyModified from './util/prettyModified'
import prettyRoundedDuration from './util/prettyRoundedDuration'

const TABLE_WIDTH = 768
const widths = [20, 20, 18, 10, 12, 12, 8]
const total = widths.reduce((a, b) => a + b, 0)
if (total !== 100) {
  throw new Error(`sum of width % should be 100, got ${total}`)
}
let paddingRemoved = 0
const pixelWidths = widths.map((width, index) => {
  const initialWidth = (width / 100) * TABLE_WIDTH
  // if first or last subtract 12px for padding otherwise subtract 24px
  const padding = index === 0 || index === widths.length - 1 ? 12 : 24
  paddingRemoved += padding
  return Math.floor(initialWidth - padding)
})

// sum pixelWidths to get the total width
const totalWidth = pixelWidths.reduce((a, b) => a + b, 0) + paddingRemoved
if (totalWidth > TABLE_WIDTH) {
  throw new Error(
    `sum of pixel widths should be less than or equal to ${TABLE_WIDTH}, got ${totalWidth}`,
  )
}

function addVariance(value: number): number {
  return Math.floor(value + value * (Math.random() * 0.3 - 0.15))
}
const varianceGrid = new Array(7)
  .fill(0)
  .map(() => new Array(10).fill(0).map(() => addVariance(85)))

const fakeTranscripts = new Array(10).fill(0).map((_, index) => ({
  id: index,
}))
interface TranscriptMetadataTableProps {
  transcripts: TranscriptMetadata[] | null
  sort: TranscriptMetadataParams['sort']
  direction: TranscriptMetadataParams['direction']
  optionsDispatch: Dispatch<OptionsAction>
  className?: string
  isPending: boolean
  deleteTranscript: (transcript: TranscriptMetadata) => Promise<void>
  refreshData: () => void
}

// the lint plugin has a bug triggering false positives on table cells:
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/issues/959
/* eslint-disable jsx-a11y/control-has-associated-label */
export default function TranscriptMetadataTable({
  transcripts,
  sort,
  direction,
  isPending,
  deleteTranscript,
  optionsDispatch,
  refreshData,
}: TranscriptMetadataTableProps): React.ReactNode {
  function getSortingIcon(key: string): React.ReactNode {
    if (sort === key) {
      return direction === 'ascending' ? <IconArrowUp size={20} /> : <IconArrowDown size={20} />
    }
    return (
      <IconArrowUp
        size={20}
        css={css`
          visibility: hidden;
        `}
      />
    )
  }

  const sortableColumns: (TableColumn<TranscriptMetadata> & {
    id: NonNullable<TranscriptMetadataParams['sort']>
  })[] = [
    {
      id: 'name',
      label: 'Name',
      render: (transcript) => (
        <div data-clarity-mask="True" className="pl-1" key={transcript.id}>
          <TranscriptNameDesktop
            refreshData={refreshData}
            id={transcript.id}
            name={transcript.name}
          />
        </div>
      ),
    },
    {
      id: 'updated_on',
      label: 'Modified',
      render: (transcript) => prettyModified(transcript.updatedOn),
    },
    {
      id: 'status',
      label: 'Status',
      render: (transcript) => <JobStatus status={transcript.status} />,
    },
    {
      id: 'media_type',
      label: 'Type',
      render: (transcript) =>
        'transcriber' in transcript.options && transcript.options.transcriber === 'human'
          ? 'HITL'
          : 'AI',
    },
    {
      id: 'media_duration',
      label: 'Length',
      render: (transcript) => transcript.media.duration,
    },
  ]

  const withSortingLabels: TableColumn<TranscriptMetadata>[] = sortableColumns.map((column) => ({
    ...column,
    label: (
      <div
        role="button"
        tabIndex={0}
        onClick={() => {
          if (sort !== column.id) {
            optionsDispatch({type: 'sort', payload: {sort: column.id, direction: 'ascending'}})
          } else {
            optionsDispatch({
              type: 'sort',
              payload: {
                sort: column.id,
                direction: direction === 'ascending' ? 'descending' : 'ascending',
              },
            })
          }
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            if (sort !== column.id) {
              optionsDispatch({type: 'sort', payload: {sort: column.id, direction: 'ascending'}})
            } else {
              optionsDispatch({
                type: 'sort',
                payload: {
                  sort: column.id,
                  direction: direction === 'ascending' ? 'descending' : 'ascending',
                },
              })
            }
          }
        }}
      >
        <div className="flex items-center">
          {column.label}
          {getSortingIcon(column.id)}
        </div>
      </div>
    ),
  }))

  const unsortableColumns: TableColumn<TranscriptMetadata>[] = [
    {
      id: 'expiration',
      label: 'Expiration',
      render: (transcript) => (
        <span>{transcript.expires && prettyRoundedDuration(transcript.expires)}</span>
      ),
    },
    {
      id: 'actions',
      label: null,
      render: (transcript) => (
        <ContextMenu transcript={transcript} deleteTranscript={deleteTranscript} />
      ),
    },
  ]

  const columns: TableColumn<TranscriptMetadata>[] = [
    ...withSortingLabels,
    ...unsortableColumns,
  ].map((column, index) => ({
    ...column,
    label: <div style={{width: ` ${pixelWidths[index]}px`}}>{column.label}</div>,
  }))

  const loadingColumns: TableColumn<{id: number}>[] = columns.map((column, index) => ({
    ...column,
    render: (item) => {
      const width = varianceGrid[index][item.id]
      return (
        <Skeleton
          width={`${width}%`}
          css={css`
            animation-delay: ${300 * item.id}ms;
          `}
        />
      )
    },
    sortableBy: undefined,
  }))

  const loading = isPending || !transcripts

  return (
    <div className="overflow-auto" style={{width: `${TABLE_WIDTH}px`}}>
      {loading ? (
        <Table
          columns={loadingColumns}
          items={fakeTranscripts}
          getItemKey={(item) => item.id.toString()}
        />
      ) : (
        <TranscriptNameContextProvider>
          <Table
            columns={columns}
            items={transcripts}
            getItemKey={(transcript) => transcript.id}
            getItemProps={(transcript) => ({href: `/transcription/${transcript.id}`})}
          />
        </TranscriptNameContextProvider>
      )}
    </div>
  )
}
