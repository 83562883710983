import {css} from '@emotion/react'

import useLocalStorageState from '../../hooks/useLocalStorageState'

import {
  DEFAULT_DICTIONARY,
  DICTIONARIES,
  DICTIONARY_STORE_KEY,
  DictionaryCode,
} from './dictionaries'

const optionCss = css`
  margin: 0 10px 0 15px;
`

export default function DictionaryPreference(): React.ReactNode {
  const [dictionaryCode, setDictionaryCode] = useLocalStorageState(
    DICTIONARY_STORE_KEY,
    DEFAULT_DICTIONARY,
  )
  const currentOption =
    DICTIONARIES.find(({langCode}) => langCode === dictionaryCode) || DICTIONARIES[0]

  return (
    <div>
      {DICTIONARIES.map((option) => (
        <label key={option.langCode}>
          <input
            type="radio"
            css={optionCss}
            value={option.langCode}
            checked={currentOption.langCode === option.langCode}
            onChange={(e) => setDictionaryCode(e.target.value as DictionaryCode)}
          />
          {option.label}
        </label>
      ))}
    </div>
  )
}
