import {Button} from '@kensho/neo'
import {chunk} from 'lodash-es'
import {memo} from 'react'

import Skeleton from '../../../components/Skeleton'
import useInView from '../../../hooks/useInView'
import {APITranscriptSlice, APITranscriptToken, Stage} from '../../../types/types'
import getSpeakerColor from '../../../utils/getSpeakerColor'
import prettyDuration from '../../../utils/prettyDuration'
import {toTranscriptPath} from '../../../utils/transcriptUtils'

import KeyQuestionToggle from './KeyQuestionToggle'
import SpeakerLabel from './SpeakerLabel'
import TranscriptTokenBatch from './TranscriptTokenBatch'

// average words per line * number of lines
const TOKEN_BATCH_SIZE = 15 * 20

interface TranscriptSliceProps {
  slice: APITranscriptSlice
  stage: Stage
  sliceIndex: number
  currentTimeMs?: number
  showPlayControls: boolean
  onClickToken: (token: APITranscriptToken) => void
  seekMedia: (options: {timeSeconds: number; play?: boolean; scroll?: boolean}) => void
  hasSelectionBoundary: boolean | null
}

function TranscriptSlice(props: TranscriptSliceProps): React.ReactNode {
  const {
    stage,
    slice,
    sliceIndex,
    currentTimeMs,
    onClickToken,
    seekMedia,
    showPlayControls,
    hasSelectionBoundary,
  } = props
  const {ref, inView} = useInView({initialInView: true, debounceDelay: 300})
  const color = getSpeakerColor(slice.speakerId)
  return (
    <div
      ref={ref}
      data-type="slice"
      data-slice-index={sliceIndex}
      data-path={toTranscriptPath(sliceIndex)}
      className="mb-10 flex max-w-[100vw] flex-col"
    >
      <div
        className="flex w-full items-center justify-between gap-2"
        contentEditable="false"
        suppressContentEditableWarning
      >
        <div className="mb-1 flex flex-auto overflow-hidden">
          {inView ? (
            <SpeakerLabel slice={slice} sliceIndex={sliceIndex} speakerId={slice.speakerId} />
          ) : (
            <Skeleton width={150} height={22} />
          )}
        </div>
        <div className="flex flex-shrink-0 items-center gap-4">
          <Button
            onClick={() =>
              seekMedia({
                timeSeconds: slice.tokenMeta[0].startMs / 1000,
                play: true,
                scroll: false,
              })
            }
            rightIcon={showPlayControls ? 'PlayIcon' : undefined}
            minimal
            disabled={!showPlayControls}
            size="small"
          >
            {prettyDuration(Math.floor((slice.tokenMeta[0]?.startMs || slice.startMs) / 1000))}
          </Button>
          <KeyQuestionToggle sliceIndex={sliceIndex} slice={slice} stage={stage} />
        </div>
      </div>
      <p
        className="slice-tokens relative m-0 max-w-full py-1 text-base"
        contentEditable
        suppressContentEditableWarning
      >
        {chunk(slice.tokenMeta, TOKEN_BATCH_SIZE).map((tokens, batchIndex) => (
          <TranscriptTokenBatch
            // keep tokens rendered if they contain a selection boundary
            // so that scrolling away and switching to lower LOD rendering doesn't lose the selection
            forceTokens={hasSelectionBoundary}
            key={tokens[0].startMs}
            tokenMeta={tokens}
            tokenOffsetIndex={batchIndex * TOKEN_BATCH_SIZE}
            sliceIndex={sliceIndex}
            currentTimeMs={currentTimeMs}
            color={color}
            onClickToken={onClickToken}
            stage={stage}
            isLast={slice.tokenMeta[slice.tokenMeta.length - 1] === tokens[tokens.length - 1]}
          />
        ))}
      </p>
    </div>
  )
}

export default memo(TranscriptSlice)
