import {createContext, ReactNode, useContext} from 'react'

import {Theme} from '../types'

// Default to light theme
const ThemeContext = createContext<Theme>('light')

export interface ThemeProviderProps {
  theme: Theme
  children: ReactNode
}

export default function ThemeProvider(props: ThemeProviderProps): React.ReactNode {
  const {theme, children} = props
  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
}

export function useTheme(): Theme {
  return useContext(ThemeContext)
}
