import {css} from '@emotion/react'
import {useState} from 'react'
import {Switch} from '@kensho/neo'

import {labelCss, legalCss, hBoldCss} from '../../styles/common'
import COLORS from '../../styles/colors'
import Plus from '../../assets/plus.svg'
import Minus from '../../assets/minus.svg'

const cookieOptionCss = css`
  border: 1px solid #bbb;
  border-bottom: none;
`

const expandButtonCss = css`
  position: relative;
  display: flex;
  width: 100%;
  padding: 12px 15px;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    background: transparent;
  }
`

const expandIconCss = css`
  width: 12px;
  height: 12px;
  margin-right: 15px;
`

const buttonTitleCss = css`
  color: #444;
  text-align: left;
  margin: 0;
`

const activeTextCss = css`
  color: ${COLORS.brand.light[700]};
  margin-left: auto;
`

const expandedContentCss = css`
  padding: 15px 20px;
  background-color: #f8f8f8;
`

interface CookieOptionProps {
  className?: string
  title: string
  expandedContent: React.ReactNode
  checked: boolean
  onChange?: (setCookie: boolean) => void
}

export default function CookieOption({
  className,
  title,
  expandedContent,
  checked,
  onChange,
}: CookieOptionProps): React.ReactNode {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className={className} css={cookieOptionCss}>
      <header className="flex items-center justify-between pr-2">
        <button css={expandButtonCss} type="button" onClick={() => setExpanded((prev) => !prev)}>
          {expanded ? (
            <img src={Minus} alt="Minus" css={expandIconCss} />
          ) : (
            <img src={Plus} alt="Plus" css={expandIconCss} />
          )}
          <p css={[buttonTitleCss, labelCss, hBoldCss]}>{title}</p>
        </button>
        {onChange ? (
          <Switch
            aria-label={title}
            checked={checked}
            onChange={(event) => onChange(event.target.checked)}
          />
        ) : (
          <p className="whitespace-nowrap" css={[legalCss, activeTextCss, hBoldCss]}>
            Always Active
          </p>
        )}
      </header>

      {expanded && <div css={expandedContentCss}>{expandedContent}</div>}
    </div>
  )
}
