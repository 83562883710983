import {css} from '@emotion/react'
import {Placement, StrictModifiers} from '@popperjs/core'
import {useCallback, useState} from 'react'

import useClickOutside from '../hooks/useClickOutside'
import useMergedRef from '../hooks/useMergedRef'
import useMoveOutside from '../hooks/useMoveOutside'

import Popover from './Popover'

interface TooltipProps {
  className?: string
  target: React.ReactNode
  children: React.ReactNode
  popperModifiers?: StrictModifiers[]
  popperPlacement?: Placement
  disabled?: boolean
}

const tooltipCss = css`
  box-shadow:
    0px 8px 24px 0px rgba(16, 22, 26, 0.2),
    0px 2px 4px 0px rgba(16, 22, 26, 0.2),
    0px 0px 0px 0px rgba(16, 22, 26, 0.1);
  background: #394b59;
  color: white;
  padding: 6px 12px;
  text-align: center;
  line-height: 18px;
  max-width: 350px;

  > div > p {
    margin: 0;
  }
`

export default function Tooltip(props: TooltipProps): React.ReactNode {
  const {className, children, target, popperPlacement = 'top', popperModifiers, disabled} = props
  const [isOpen, setIsOpen] = useState(false)
  const close = useCallback(() => setIsOpen(false), [])
  const clickOutsideRef = useClickOutside(close)
  const moveOutsideRef = useMoveOutside(close)
  const mergedRef = useMergedRef(clickOutsideRef, moveOutsideRef)

  return (
    <Popover
      arrow
      arrowColor="#394b59"
      className={className}
      css={tooltipCss}
      popperModifiers={popperModifiers}
      popperPlacement={popperPlacement}
      target={
        <div
          ref={mergedRef}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          {target}
        </div>
      }
      isOpen={!disabled && isOpen}
    >
      {children}
    </Popover>
  )
}
