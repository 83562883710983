import {css} from '@emotion/react'
import {IconArticle} from '@kensho/icons'
import {useLogger} from '@kensho/lumberjack'
import {defer} from 'lodash-es'
import {memo, useCallback, useContext, useEffect, useRef} from 'react'

import CrossFade from '../../../anims/CrossFade'
import useKeyboardShortcut, {isModifier} from '../../../hooks/useKeyboardShortcut'
import {UpdateTranscriptSelectionType} from '../../../hooks/useTranscriptSelection'
import SHORTCUTS from '../../../shortcuts/shortcutRegistration'
import {
  APITranscript,
  APITranscriptToken,
  Stage,
  TranscriptMetadata,
  TranscriptSelection,
} from '../../../types/types'
import copyText from '../../../utils/copyText'
import {OperationAction, produceOperation} from '../../../utils/transcriptPatchUtils'
import {
  getTextFromTranscriptSelection,
  getTokenRange,
  getTranscriptSelection,
  isTimeInSlice,
} from '../../../utils/transcriptUtils'
import formatCase from '../../../utils/formatCase'
import {TranscriptPermissionsContext} from '../TranscriptPermissionsProvider'

import {useDispatchEditOperation} from './DispatchEditOperationProvider'
import SkeletonTranscript from './SkeletonTranscript'
import TranscriptSlice from './TranscriptSlice'

interface InputEvent<T> extends React.FormEvent<T> {
  data: string
}

interface TranscriptProps {
  metadata?: TranscriptMetadata
  showPlayControls: boolean
  transcript?: APITranscript
  stage: Stage
  currentTimeMs?: number
  paused: boolean
  onClickToken: (token: APITranscriptToken) => void
  seekMedia: (options: {timeSeconds: number; play?: boolean; scroll?: boolean}) => void
  undo: () => void
  redo: () => void
  updateTranscriptSelection: UpdateTranscriptSelectionType
  transcriptSelection: TranscriptSelection | null
  onEditOperationError: (error: Error, action: OperationAction) => void
  isTranscriptFocused: boolean
  setIsTranscriptFocused: React.Dispatch<React.SetStateAction<boolean>>
}

const transcriptCss = css`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  font-weight: normal;
  white-space: pre-wrap;
  overflow: auto;
  width: 100%;
  height: 100%;

  a {
    color: #333;
  }

  &:focus {
    outline: none;
  }
`

const emptyTranscriptCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-style: italic;
`

const emptyTranscriptIconCss = css`
  margin-right: 10px;
`
function Transcript(props: TranscriptProps): React.ReactNode {
  const {
    metadata,
    transcript,
    onEditOperationError,
    stage,
    currentTimeMs = 0,
    onClickToken,
    seekMedia,
    showPlayControls,
    undo,
    redo,
    transcriptSelection,
    updateTranscriptSelection,
    setIsTranscriptFocused,
  } = props
  const log = useLogger()
  const {dispatchEditOperation} = useDispatchEditOperation()
  const {transcriptPermissions} = useContext(TranscriptPermissionsContext)

  useKeyboardShortcut(SHORTCUTS.Edit.undo.keys, () => {
    if (!transcriptPermissions.edit) return
    undo()
  })

  useKeyboardShortcut(SHORTCUTS.Edit.redo.keys, () => {
    if (!transcriptPermissions.edit) return
    redo()
  })

  useKeyboardShortcut(
    SHORTCUTS.Edit.toggleCapitalization.keys,
    () => {
      if (!transcriptPermissions.edit) return
      if (!transcript) return
      if (!transcriptSelection) return
      const {start, end} = transcriptSelection
      if (!start || !end) return

      const startSliceIndex = start.sliceIndex
      const endSliceIndex = end.sliceIndex
      let {tokenIndex: startTokenIndex} = start
      let {tokenIndex: endTokenIndex} = end

      // noop
      // foo[ ]bar
      if (
        start.type === 'token-space' &&
        end.type === 'token-space' &&
        start.tokenIndex === end.tokenIndex &&
        start.textOffset === 0 &&
        end.textOffset === 1
      ) {
        return
      }

      if (start.type === 'token-space' && start.textOffset > 0) {
        if (startTokenIndex + 1 < transcript.sliceMeta[startSliceIndex].tokenMeta.length) {
          // if the selection starts on a space adjust the token index to the next natural token
          // because the token-space index points to the preceding token index
          // foo |bar -> foo |Bar
          startTokenIndex += 1
          endTokenIndex += 1
        } else if (endSliceIndex === startSliceIndex && endTokenIndex === startTokenIndex) {
          // the selection is after the end of a slice which has no following natural token
          return
        }
      } else if (
        start.type === 'token-space' &&
        start.textOffset === 0 &&
        end.type !== 'token-space'
      ) {
        // foo[ b]ar -> foo[ B]ar
        startTokenIndex += 1
      }

      // if the selection ends on a space adjust the token index to the next natural token
      // because the token-space index points to the preceding token index
      // foo |bar -> foo |Bar
      if (
        end.type === 'token-space' &&
        end.textOffset > 0 &&
        (startSliceIndex !== endSliceIndex || startTokenIndex !== endTokenIndex) &&
        endTokenIndex + 1 < transcript.sliceMeta[endSliceIndex].tokenMeta.length
      ) {
        endTokenIndex += 1
      }

      const action: OperationAction = {
        type: 'replace-tokens',
        data: getTokenRange(transcript, {
          startToken: {sliceIndex: startSliceIndex, tokenIndex: startTokenIndex},
          endToken: {sliceIndex: endSliceIndex, tokenIndex: endTokenIndex},
        }),
      }

      const shouldCapitalize = /^[a-z]/.test(action.data[0].token.transcript)

      action.data = action.data.map((indexedToken) => ({
        ...indexedToken,
        token: {
          ...indexedToken.token,
          transcript: formatCase(indexedToken.token.transcript, shouldCapitalize),
        },
      }))

      const operation = produceOperation({
        action,
        transcript,
        transcriptSelection: getTranscriptSelection(),
        onError: onEditOperationError,
        log,
      })
      if (!operation) return

      dispatchEditOperation(operation)
    },
    {preventDefault: true},
  )

  const transcriptRef = useRef<HTMLDivElement | null>(null)

  const populateEmptyTranscript = useCallback(() => {
    if (!transcript) return
    if (!transcriptPermissions.edit) return

    const operation = produceOperation({
      action: {type: 'insert-text', data: ' '},
      transcript,
      transcriptSelection: {
        type: 'Caret',
        start: null,
        end: null,
      },
      log,
    })
    if (!operation) return

    dispatchEditOperation(operation)
  }, [transcript, transcriptPermissions, log, dispatchEditOperation])

  // keep transcript selection state in sync when window selection might change
  // using mouseup and keyup events instead of selectionchange because selectionchange gets called
  // more frequently (e.g. when dragging while mousedown it gets updated on every new character collision)
  useEffect(() => {
    const handleRangeSelection = (event: MouseEvent | KeyboardEvent): void => {
      if ('key' in event && isModifier(event.key)) return

      // defer for one case when going from range selection to caret selection with click
      // because the selection has not updated yet
      defer(() => {
        const selection = getTranscriptSelection()
        updateTranscriptSelection(selection, false, false)
      })
    }

    document.addEventListener('mouseup', handleRangeSelection)
    document.addEventListener('keyup', handleRangeSelection)
    return () => {
      document.removeEventListener('mouseup', handleRangeSelection)
      document.removeEventListener('keyup', handleRangeSelection)
    }
  }, [updateTranscriptSelection])

  // keep isTranscriptFocused state in sync
  useEffect(() => {
    const transcriptEle = transcriptRef.current

    const onFocusIn = (): void => setIsTranscriptFocused(true)
    const onFocusOut = (): void => setIsTranscriptFocused(false)

    transcriptEle?.addEventListener('focusin', onFocusIn)
    transcriptEle?.addEventListener('focusout', onFocusOut)

    return () => {
      transcriptEle?.removeEventListener('focusin', onFocusIn)
      transcriptEle?.removeEventListener('focusout', onFocusOut)
    }
  }, [setIsTranscriptFocused])

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div
      data-clarity-mask="True"
      ref={transcriptRef}
      css={transcriptCss}
      data-testid="transcript"
      contentEditable
      suppressContentEditableWarning
      spellCheck={false}
      onKeyDown={(event) => {
        if (!transcriptPermissions.edit) {
          event.preventDefault()
          return
        }
        if (event.key === 'a' && event.metaKey && !event.shiftKey && !event.altKey && transcript) {
          const lastSliceIndex = Math.max(transcript.sliceMeta.length - 1, 0)
          const lastTokenIndex = Math.max(
            transcript.sliceMeta[lastSliceIndex].tokenMeta.length - 1,
            0,
          )
          updateTranscriptSelection(
            {
              type: 'Range',
              start: {type: 'token', sliceIndex: 0, tokenIndex: 0, textOffset: 0},
              end: {
                type: 'token-space',
                sliceIndex: lastSliceIndex,
                tokenIndex: lastTokenIndex,
                textOffset: 0,
              },
            },
            false,
            // explicitly setting force = false, because we don't render offscreen tokens that are not part of selection
            // so have to wait for effect to update selection and render those, otherwise we try too early
            // and return null because elements don't exist in DOM
            false,
          )
        }

        if (['Backspace', 'Delete', 'Enter'].includes(event.key)) {
          event.preventDefault()

          if (!transcript) return

          let action: OperationAction | undefined
          if (event.key === 'Backspace' || event.key === 'Delete') {
            action = {
              type: 'delete-text',
              direction: event.key === 'Backspace' ? 'backward' : 'forward',
            }
          }
          if (event.key === 'Enter') {
            action = {type: 'split-slice'}
          }
          if (!action) return

          const operation = produceOperation({
            action,
            transcript,
            transcriptSelection: getTranscriptSelection(),
            onError: onEditOperationError,
            log,
          })
          if (!operation) return

          dispatchEditOperation(operation)
        }
      }}
      onBeforeInput={(event) => {
        event.preventDefault()

        if (!transcript) return
        if (!transcriptPermissions.edit) return
        if (!transcriptSelection) return

        const operation = produceOperation({
          action: {type: 'insert-text', data: (event as InputEvent<HTMLDivElement>).data},
          transcript,
          transcriptSelection,
          onError: onEditOperationError,
          log,
        })
        dispatchEditOperation(operation)
      }}
      onCopy={(event) => {
        event.preventDefault()

        if (!transcript) return
        if (!transcriptSelection) return

        // update the transcript selection first
        // in case window.getSelection() is not synced with transcriptSelection
        // this is only expected to happen after cmd+a due to our LOD token rendering
        // since copyText is giong to use winow.getSelection() to restore
        updateTranscriptSelection(transcriptSelection, false, true)

        const text = getTextFromTranscriptSelection(transcript, transcriptSelection)
        copyText(text)
      }}
      onCut={(event) => {
        event.preventDefault()

        if (!transcript) return
        if (!transcriptPermissions.edit) return
        if (!transcriptSelection) return

        // manually copy cut text to clipboard because we don't want cut event to modify dom
        const text = getTextFromTranscriptSelection(transcript, transcriptSelection)
        copyText(text)

        const operation = produceOperation({
          action: {type: 'delete-text'},
          transcript,
          transcriptSelection,
          onError: onEditOperationError,
          log,
        })
        dispatchEditOperation(operation)
      }}
      onPaste={(event) => {
        event.preventDefault()

        if (!transcript) return
        if (!transcriptPermissions.edit) return
        if (!transcriptSelection) return

        const operation = produceOperation({
          action: {type: 'insert-text', data: event.clipboardData.getData('text/plain')},
          transcript,
          transcriptSelection,
          onError: onEditOperationError,
          log,
        })

        dispatchEditOperation(operation)
      }}
    >
      <CrossFade in={!transcript}>
        <SkeletonTranscript metadata={metadata} />
      </CrossFade>
      <CrossFade in={!!transcript}>
        <>
          {stage === 'POST_TRANSCRIPTION' && transcript?.sliceMeta.length === 0 ? (
            <div
              css={emptyTranscriptCss}
              onClick={() => {
                if (!transcriptPermissions.edit) return
                populateEmptyTranscript()
              }}
              onKeyDown={(event) => {
                if (!transcriptPermissions.edit) return
                if (event.key === 'Enter') {
                  populateEmptyTranscript()
                }
              }}
            >
              <IconArticle css={emptyTranscriptIconCss} size={22} />
              Transcript is empty. {transcriptPermissions.edit ? 'Click to add.' : ''}
            </div>
          ) : (
            (transcript?.sliceMeta || []).map((slice, i) => {
              const hasSelectionBoundary =
                i === transcriptSelection?.start?.sliceIndex ||
                i === transcriptSelection?.end?.sliceIndex

              return (
                <TranscriptSlice
                  hasSelectionBoundary={hasSelectionBoundary}
                  showPlayControls={showPlayControls}
                  key={`${slice.startMs}:${slice.durationMs}:${slice.accuracy}`}
                  stage={stage}
                  slice={slice}
                  sliceIndex={i}
                  currentTimeMs={
                    stage === 'POST_TRANSCRIPTION' &&
                    currentTimeMs !== 0 &&
                    isTimeInSlice(currentTimeMs, slice)
                      ? currentTimeMs
                      : undefined
                  }
                  onClickToken={onClickToken}
                  seekMedia={seekMedia}
                />
              )
            })
          )}
        </>
      </CrossFade>
    </div>
  )
}

export default memo(Transcript)
