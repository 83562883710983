import {useContext} from 'react'
import clsx from 'clsx'

import Ellipsis from '../../../components/Ellipsis'
import useEditableTranscriptName from '../../../hooks/useEditableTranscriptName'
import {TranscriptionConfiguration} from '../../../types/types'
import {TranscriptPermissionsContext} from '../TranscriptPermissionsProvider'

interface TranscriptNameProps {
  name: string
  id: string
  setTranscriptionConfiguration?: React.Dispatch<React.SetStateAction<TranscriptionConfiguration>>
}

export default function TranscriptName(props: TranscriptNameProps): React.ReactNode {
  const {name, id, setTranscriptionConfiguration} = props
  const {transcriptPermissions} = useContext(TranscriptPermissionsContext)
  const {isEditing, value, contentRef, getTitleProps, getButtonProps, status} =
    useEditableTranscriptName({
      name,
      id,
      onUpdateName:
        setTranscriptionConfiguration &&
        (() => {
          setTranscriptionConfiguration((prevTranscriptionConfiguration) => ({
            ...prevTranscriptionConfiguration,
            name: value,
          }))
        }),
    })

  return (
    <div data-clarity-mask="True" className="mb-2">
      {status === 'pending' && <Ellipsis />}
      {status !== 'pending' && isEditing && transcriptPermissions.edit && (
        <div
          className="w-full cursor-text rounded-lg text-left text-xl outline-none hover:ring-1 hover:ring-gray-400 focus-visible:ring-2 focus-visible:ring-cyan-600"
          ref={contentRef}
          data-testid="transcript-name"
          role="textbox"
          contentEditable
          suppressContentEditableWarning
          {...getTitleProps()}
        >
          {value}
        </div>
      )}
      {status !== 'pending' && (!isEditing || !transcriptPermissions.edit) && (
        <div>
          <button
            type="button"
            className={clsx(
              'w-full rounded-lg text-left text-xl outline-none',
              transcriptPermissions.edit && 'cursor-pointer hover:ring-1 hover:ring-gray-400',
            )}
            {...getButtonProps()}
            data-testid="transcript-name"
          >
            {value}
          </button>
        </div>
      )}
    </div>
  )
}
