import {usePrevious} from '@kensho/tacklebox'
import {throttle} from 'lodash-es'
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import {useParams} from 'react-router-dom'
import clsx from 'clsx'

import FadeInOut from '../../../anims/FadeInOut'
import useGetTranscript from '../../../api/useGetTranscript'
import ErrorDialog from '../../../components/ErrorDialog'
import {UpdateTranscriptSelectionType} from '../../../hooks/useTranscriptSelection'
import TranscriptContext from '../../../providers/TranscriptContext'
import {
  APITranscript,
  APITranscriptToken,
  Mode,
  ScribeError,
  Stage,
  TranscriptSelection,
  TranscriptionConfiguration,
} from '../../../types/types'
import smoothScrollElement, {SmoothScrollElementOptions} from '../../../utils/smoothScrollElement'
import {OperationAction} from '../../../utils/transcriptPatchUtils'
import {getTokenAtTime} from '../../../utils/transcriptUtils'
import {TranscriptPermissionsContext} from '../TranscriptPermissionsProvider'

import Transcript from './Transcript'
import TranscriptHeader from './TranscriptHeader'
import useBackgroundAligner from './useBackgroundAligner'

interface TranscriptContainerProps {
  showPlayControls: boolean
  mode?: Mode
  stage: Stage
  currentTimeMs: number
  paused: boolean
  onClickToken: (token: APITranscriptToken) => void
  seekMedia: (options: {timeSeconds: number; play?: boolean; scroll?: boolean}) => void
  transcriptionConfiguration: TranscriptionConfiguration
  setTranscriptionConfiguration: React.Dispatch<React.SetStateAction<TranscriptionConfiguration>>
  onEditOperationError: (error: Error, action: OperationAction) => void
  updateTranscriptSelection: UpdateTranscriptSelectionType
  transcriptSelection: TranscriptSelection | null
  undo: () => void
  redo: () => void
  isTranscriptFocused: boolean
  setIsTranscriptFocused: React.Dispatch<React.SetStateAction<boolean>>
  lastSaved?: Date
  setPaused: React.Dispatch<React.SetStateAction<boolean>>
}

export interface TranscriptContainerRef {
  scrollTranscriptToTime: (timeMs: number) => void
}

function TranscriptContainer(
  props: TranscriptContainerProps,
  ref: React.Ref<TranscriptContainerRef>,
): React.ReactNode {
  const {
    currentTimeMs,
    paused,
    onClickToken,
    seekMedia,
    showPlayControls,
    mode,
    stage,
    transcriptionConfiguration,
    setTranscriptionConfiguration,
    onEditOperationError,
    transcriptSelection,
    updateTranscriptSelection,
    undo,
    redo,
    isTranscriptFocused,
    setIsTranscriptFocused,
    lastSaved,
    setPaused,
  } = props
  const {transcriptId} = useParams()
  const prevTranscriptId = usePrevious(transcriptId)
  const {transcript, metadata, dispatch} = useContext(TranscriptContext)
  const {transcriptPermissions} = useContext(TranscriptPermissionsContext)
  const [error, setError] = useState<ScribeError>()
  const [failedToFetchTranscript, setFailedToFetchTranscript] = useState(false)

  const [, getTranscript] = useGetTranscript()
  // fetch json transcript when transcription job is finished processing
  // this happens after we retrieve the transcript metadata so we can look at the status of the job
  useEffect(() => {
    let isCurrent = true

    if (
      mode !== 'REALTIME' &&
      stage === 'POST_TRANSCRIPTION' &&
      metadata?.status === 'complete' &&
      transcriptId &&
      (!transcript || transcriptId !== prevTranscriptId) &&
      !failedToFetchTranscript
    ) {
      getTranscript({transcriptId})
        .then((result) => {
          if (isCurrent) dispatch({type: 'setTranscript', transcript: result as APITranscript})
        })
        .catch((e: ScribeError) => {
          if (isCurrent) {
            setError(e)
            setFailedToFetchTranscript(true)
          }
        })
    }

    return () => {
      isCurrent = false
    }
  }, [
    mode,
    stage,
    transcriptId,
    prevTranscriptId,
    transcript,
    getTranscript,
    dispatch,
    metadata?.status,
    failedToFetchTranscript,
  ])

  // scrolling management
  const [syncScrollToTime, setSyncScrollToTime] = useState(true)
  const isProgrammaticScrollingRef = useRef(false)
  const queuedAutoScrollChangeRef = useRef(false)
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  const scrollTranscriptToPosition = useCallback(
    (smoothScrollOptions: SmoothScrollElementOptions): Promise<void> => {
      if (!scrollContainerRef.current || isProgrammaticScrollingRef.current)
        return Promise.resolve()

      // add a flag to disable other programmatic scroll events so we don't fight ourselves
      isProgrammaticScrollingRef.current = true

      return smoothScrollElement(scrollContainerRef.current, smoothScrollOptions).then(() => {
        isProgrammaticScrollingRef.current = false
      })
    },
    [],
  )
  // scroll transcript to closest token based on time
  const scrollTranscriptToTime = useCallback(
    (
      timeMs: number,
      options?: {
        top?: number
        bottom?: number
        scrollDuration?: number
      },
    ): Promise<void> => {
      // specifying a top/bottom value will shrink the area considered as visible
      const {top = 0, bottom = 0, scrollDuration} = options || {}

      if (isProgrammaticScrollingRef.current) return Promise.resolve()
      if (!transcript?.sliceMeta.length) return Promise.resolve()
      if (!scrollContainerRef.current) return Promise.resolve()

      const token = getTokenAtTime(transcript.sliceMeta, timeMs)
      if (!token) return Promise.resolve()
      const [currentToken, currentTokenIndex, currentSliceIndex] = token

      const sliceEle = document.querySelector<HTMLDivElement>(
        `div[data-slice-index="${currentSliceIndex}"]`,
      )
      const sliceTranscriptEle = sliceEle?.querySelector('p.slice-tokens')
      if (!sliceTranscriptEle) return Promise.resolve()

      const tokenEle = document.querySelector<HTMLSpanElement>(
        `span[data-start-ms="${currentToken.startMs}"]`,
      )

      // since we don't have a token element (token is off-screen)
      // create a Range and select the token which is part of a token batch node
      const tokenRange = new Range()
      if (!tokenEle) {
        let foundTokenIndex = 0
        const nodeQueue = [...sliceTranscriptEle.childNodes]
        let node = nodeQueue[0]
        let startOffset = 0
        let endOffset = 0
        // iterate over text nodes until we find the current token
        while (foundTokenIndex < currentTokenIndex && nodeQueue.length) {
          node = nodeQueue.shift() as ChildNode
          startOffset = 0
          endOffset = 0

          if (node.textContent && node.textContent !== ' ') {
            if (node.nodeType === 3 /* TEXT_NODE */) {
              const words = node.textContent === ' ' ? [] : (node.textContent || '').split(' ')
              let wordIndex = 0
              let prevWord
              while (wordIndex < words.length && foundTokenIndex < currentTokenIndex) {
                startOffset = endOffset
                // + 1 for space after prevWord
                endOffset += words[wordIndex].length - 1 + (prevWord ? 1 : 0)
                prevWord = words[wordIndex]
                foundTokenIndex += 1
                wordIndex += 1
              }
            } else {
              nodeQueue.unshift(...node.childNodes)
            }
          }
        }

        tokenRange.setStart(node, startOffset)
        // use character offset if text node, otherwise the node's child index
        tokenRange.setEnd(node, endOffset || 1)
      }

      const tokenRect = (tokenEle || tokenRange).getBoundingClientRect()
      const containerRect = scrollContainerRef.current.getBoundingClientRect()

      // scroll the transcript container if the token is not already visible
      // attempt to center the token vertically
      if (
        tokenRect.top < containerRect.top + top ||
        tokenRect.bottom > containerRect.bottom - bottom
      ) {
        const nextTop =
          tokenRect.top +
          scrollContainerRef.current.scrollTop -
          containerRect.height / 2 -
          containerRect.top
        return scrollTranscriptToPosition({top: nextTop, duration: scrollDuration})
      }

      return Promise.resolve()
    },
    [transcript?.sliceMeta, scrollTranscriptToPosition],
  )
  const scrollTranscriptToTimeThrottled = useMemo(
    () =>
      throttle(
        scrollTranscriptToTime,
        1000,
        // ignore trailing since autoScroll might be disabled during last throttled delay
        {trailing: false},
      ),
    [scrollTranscriptToTime],
  )

  useEffect(() => {
    queuedAutoScrollChangeRef.current = false
  }, [syncScrollToTime])
  // auto-scroll transcript during realtime TRANSCRIPTION by scrolling to the bottom
  useEffect(() => {
    if (
      queuedAutoScrollChangeRef.current ||
      !syncScrollToTime ||
      !transcript ||
      !scrollContainerRef.current ||
      isProgrammaticScrollingRef.current ||
      !(mode === 'REALTIME' && stage === 'TRANSCRIPTION')
    )
      return

    scrollTranscriptToPosition({top: scrollContainerRef.current.scrollHeight, duration: 300})
  }, [transcript, mode, stage, syncScrollToTime, scrollTranscriptToPosition])
  // auto-scroll transcript during POST_TRANSCRIPTION whenever the currentTime updates
  useEffect(() => {
    if (
      queuedAutoScrollChangeRef.current ||
      paused ||
      !syncScrollToTime ||
      !transcript ||
      !scrollContainerRef.current ||
      isProgrammaticScrollingRef.current ||
      stage !== 'POST_TRANSCRIPTION'
    )
      return

    scrollTranscriptToTimeThrottled(currentTimeMs, {
      // add a one line buffer from the bottom to trigger autoscroll
      // so that active token doesn't go past the bottom of the screen
      bottom: 24,
    })
  }, [paused, transcript, stage, syncScrollToTime, currentTimeMs, scrollTranscriptToTimeThrottled])

  useImperativeHandle(
    ref,
    () => ({
      scrollTranscriptToTime,
    }),
    [scrollTranscriptToTime],
  )

  const backgroundAlignerStatus = useBackgroundAligner({
    transcriptId,
    transcript,
    mode,
    stage,
    disabled: !transcriptPermissions.edit,
  })

  return (
    <div className="max-w-auto relative h-full w-[800px] flex-auto pr-4 lg:max-w-[800px]">
      <div
        ref={scrollContainerRef}
        className="relative mt-8 flex h-full flex-col overflow-auto pr-4"
      >
        <div className="flex justify-center gap-[120px] sm:flex-col sm:gap-0 md:gap-[60px]">
          <div className="ml-3 mt-4 flex w-full flex-col gap-5 transition-all duration-1000 ease-in-out">
            <FadeInOut in={['TRANSCRIPTION', 'POST_TRANSCRIPTION'].includes(stage)}>
              <TranscriptHeader
                stage={stage}
                metadata={metadata}
                mode={mode}
                transcript={transcript}
                transcriptId={transcriptId}
                transcriptionConfiguration={transcriptionConfiguration}
                setTranscriptionConfiguration={setTranscriptionConfiguration}
                onEditOperationError={onEditOperationError}
                transcriptSelection={transcriptSelection}
                updateTranscriptSelection={updateTranscriptSelection}
                scrollTranscriptToTime={scrollTranscriptToTime}
                lastSaved={lastSaved}
                undo={undo}
                redo={redo}
                backgroundAlignerStatus={backgroundAlignerStatus}
                syncScrollToTime={syncScrollToTime}
                setSyncScrollToTime={setSyncScrollToTime}
                scrollTranscriptToCurrentTime={() => scrollTranscriptToTime(currentTimeMs)}
                seekMedia={seekMedia}
                currentTime={currentTimeMs}
                setPaused={setPaused}
                hasMedia={showPlayControls}
              />
            </FadeInOut>
            <div
              className={clsx(
                'relative flex h-full max-w-full flex-1 flex-col items-center justify-center transition-all duration-1000 ease-in-out md:h-auto md:flex-shrink md:flex-grow',
                mode === 'REALTIME' && 'mt-5',
              )}
            >
              <FadeInOut in={['TRANSCRIPTION', 'POST_TRANSCRIPTION'].includes(stage)}>
                <Transcript
                  metadata={metadata}
                  showPlayControls={showPlayControls}
                  transcript={transcript}
                  currentTimeMs={currentTimeMs}
                  paused={paused}
                  stage={stage}
                  onClickToken={onClickToken}
                  seekMedia={seekMedia}
                  undo={undo}
                  redo={redo}
                  transcriptSelection={transcriptSelection}
                  updateTranscriptSelection={updateTranscriptSelection}
                  onEditOperationError={onEditOperationError}
                  isTranscriptFocused={isTranscriptFocused}
                  setIsTranscriptFocused={setIsTranscriptFocused}
                />
              </FadeInOut>
            </div>
          </div>
        </div>
      </div>

      <ErrorDialog
        isOpen={!!error}
        error={error}
        onClose={() => {
          setError(undefined)
          setFailedToFetchTranscript(false)
        }}
      />
    </div>
  )
}

export default forwardRef(TranscriptContainer)
