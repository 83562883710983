import {useState, ReactNode} from 'react'

import {
  BatchMachineTranscriptionConfiguration,
  Mode,
  RealtimeStatus,
  Stage,
  TranscriptionConfiguration,
} from '../../../types/types'

import {FormState} from './FormState'
import BatchConfigForm from './BatchConfigForm'
import RealtimeConfigForm from './RealtimeConfigForm'

interface TranscriptionConfigProps {
  mode: Mode
  stage: Stage
  setStage: React.Dispatch<React.SetStateAction<Stage>>
  speechFile?: File
  transcriptionConfiguration: TranscriptionConfiguration
  setTranscriptionConfiguration: React.Dispatch<React.SetStateAction<TranscriptionConfiguration>>
  audioInputDeviceId?: string
  onAudioInputDeviceChange: (nextAudioDeviceInfoId?: string) => void
  setAudioInputDeviceReady: React.Dispatch<React.SetStateAction<boolean>>
  setTranscriptId: (nextTranscriptId?: string) => void
  resetAudioAndTranscript: () => void
  setRealtimeStatus: React.Dispatch<React.SetStateAction<RealtimeStatus>>
  realtimeStatus: RealtimeStatus
}
export default function TranscriptionConfig(props: TranscriptionConfigProps): ReactNode {
  const {
    mode,
    stage,
    setStage,
    speechFile,
    transcriptionConfiguration,
    setTranscriptionConfiguration,
    setTranscriptId,
    resetAudioAndTranscript,
    onAudioInputDeviceChange,
    setAudioInputDeviceReady,
    audioInputDeviceId,
    setRealtimeStatus,
    realtimeStatus,
  } = props

  const defaultHotwords =
    'hotwords' in transcriptionConfiguration ? transcriptionConfiguration.hotwords : [] || []
  const [formState, setFormState] = useState<FormState>({
    transcriptName: transcriptionConfiguration.name || '',
    hotwords: new Set<string>(defaultHotwords),
    errors: {},
  })

  return (
    <div className="mx-auto mt-10 h-full w-[550px] overflow-auto px-6 py-5">
      {mode === 'BATCH' && (
        <>
          <BatchConfigForm
            formState={formState}
            setFormState={setFormState}
            resetAudioAndTranscript={resetAudioAndTranscript}
            setStage={setStage}
            setTranscriptId={setTranscriptId}
            speechFile={speechFile}
            setTranscriptionConfiguration={setTranscriptionConfiguration}
            stage={stage}
            transcriptionConfiguration={
              transcriptionConfiguration as BatchMachineTranscriptionConfiguration
            }
          />
        </>
      )}
      {mode === 'REALTIME' && (
        <RealtimeConfigForm
          formState={formState}
          setFormState={setFormState}
          onAudioInputDeviceChange={onAudioInputDeviceChange}
          setAudioInputDeviceReady={setAudioInputDeviceReady}
          resetAudioAndTranscript={resetAudioAndTranscript}
          setRealtimeStatus={setRealtimeStatus}
          setTranscriptionConfiguration={setTranscriptionConfiguration}
          realtimeStatus={realtimeStatus}
          transcriptionConfiguration={transcriptionConfiguration}
          audioInputDeviceId={audioInputDeviceId}
        />
      )}
    </div>
  )
}
