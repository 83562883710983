import MenuContext, {DEFAULT_MENU_CONTEXT} from '../menu/MenuContext'

import DisplayGroupContext from './DisplayGroupContext'

export interface ClearGroupProps {
  children: React.ReactNode
}

/** Clears the group context, if any. */
export default function ClearGroup(props: ClearGroupProps): React.ReactNode {
  const {children} = props
  return (
    <DisplayGroupContext.Provider value={null}>
      <MenuContext.Provider value={DEFAULT_MENU_CONTEXT}>{children}</MenuContext.Provider>
    </DisplayGroupContext.Provider>
  )
}
