import {Button, ProgressBar} from '@kensho/neo'
import {uniqueId} from 'lodash-es'
import {useCallback, useContext, useEffect, useRef, useState} from 'react'

import CrossFade from '../../../anims/CrossFade'
import useUploadBatch from '../../../api/useUploadBatch'
import ErrorDialog from '../../../components/ErrorDialog'
import Skeleton from '../../../components/Skeleton'
import SiteAnalyticsContext from '../../../providers/SiteAnalyticsContext'
import TranscriptContext from '../../../providers/TranscriptContext'
import {
  BatchMachineTranscriptionConfiguration,
  ScribeError,
  Stage,
  TranscriptionConfiguration,
} from '../../../types/types'

import {FormState} from './FormState'
import TranscriptName from './TranscriptName'

interface BatchConfigFormProps {
  stage: Stage
  setStage: React.Dispatch<React.SetStateAction<Stage>>
  speechFile?: File
  transcriptionConfiguration: BatchMachineTranscriptionConfiguration
  setTranscriptId: (nextTranscriptId?: string) => void
  resetAudioAndTranscript: () => void
  setTranscriptionConfiguration: React.Dispatch<React.SetStateAction<TranscriptionConfiguration>>
  formState: FormState
  setFormState: React.Dispatch<React.SetStateAction<FormState>>
}

export default function BatchConfigForm(props: BatchConfigFormProps): React.ReactNode {
  const {
    stage,
    setStage,
    speechFile,
    setTranscriptId,
    resetAudioAndTranscript,
    transcriptionConfiguration,
    setTranscriptionConfiguration,
    formState,
    setFormState,
  } = props

  const {dispatch: transcriptContextDispatch} = useContext(TranscriptContext)
  const [scribeError, setScribeError] = useState<ScribeError>()
  const [uploadBatchStatus, uploadBatch, cancelUploadBatch] = useUploadBatch()
  const uploadRequestID = useRef<string>()

  const analytics = useContext(SiteAnalyticsContext)

  const startTranscription = useCallback((): void => {
    if (!speechFile) return
    if (Object.values(formState.errors).some((error) => error)) return
    const newTranscriptionConfiguration = {
      ...transcriptionConfiguration,
      name: formState.transcriptName,
      hotwords: formState.hotwords,
    }

    setTranscriptionConfiguration(newTranscriptionConfiguration)

    transcriptContextDispatch({type: 'reset'})
    setTranscriptId()
    setStage('START_TRANSCRIPTION')

    const requestId = uniqueId('batch-upload')
    uploadRequestID.current = requestId

    uploadBatch({file: speechFile, options: newTranscriptionConfiguration})
      .then((id: string) => {
        if (requestId !== uploadRequestID.current) return // noop
        setTranscriptId(id)
      })
      .catch((e: DOMException | ScribeError) => {
        if (requestId !== uploadRequestID.current) return // noop

        if (e instanceof DOMException && e.name === 'AbortError') return // noop
        setScribeError(e instanceof DOMException ? {type: 'unknown'} : e)
        setStage('PRE_TRANSCRIPTION')
      })
      .finally(() => {
        if (requestId === uploadRequestID.current) {
          uploadRequestID.current = undefined
        }
      })
  }, [
    speechFile,
    transcriptContextDispatch,
    setTranscriptId,
    uploadBatch,
    transcriptionConfiguration,
    setStage,
    formState,
    setTranscriptionConfiguration,
  ])

  useEffect(
    () => () => {
      uploadRequestID.current = undefined
    },
    [],
  )

  return (
    <>
      {['PRE_TRANSCRIPTION'].includes(stage) && (
        <div className="flex flex-col gap-10">
          <div className="text-lg font-semibold">Start a new transcription</div>
          <TranscriptName
            error={formState.errors.transcriptName}
            setFormState={setFormState}
            transcriptName={formState.transcriptName}
          />
        </div>
      )}
      <div className="mt-5">
        <ErrorDialog
          isOpen={!!scribeError}
          error={scribeError}
          onClose={() => setScribeError(undefined)}
        />
        {['PRE_TRANSCRIPTION'].includes(stage) && (
          <CrossFade in={uploadBatchStatus === 'idle' || uploadBatchStatus === 'error'}>
            <div className="flex flex-col gap-10">
              <div className="flex justify-end gap-4">
                {speechFile ? (
                  <>
                    <Button
                      onClick={() => {
                        analytics.sendEvent('cancel_batch')
                        resetAudioAndTranscript()
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={
                        uploadBatchStatus === 'pending' ||
                        Object.values(formState.errors).some((error) => error)
                      }
                      intent="primary"
                      onClick={() => {
                        analytics.sendEvent('transcribe_batch')
                        startTranscription()
                      }}
                    >
                      Transcribe
                    </Button>
                  </>
                ) : (
                  <Skeleton width={200} height={40} />
                )}
              </div>
            </div>
          </CrossFade>
        )}
        <CrossFade in={uploadBatchStatus === 'pending'} exitDuration={0}>
          <div className="mt-10">
            <div className="mb-3 mt-2 text-lg">Uploading…</div>
            <ProgressBar />
            <div className="mb-4 mt-2 text-sm text-gray-500">
              This may take several minutes depending on file size and connection speed.
            </div>
            <Button
              onClick={() => {
                uploadRequestID.current = undefined
                analytics.sendEvent('cancel_batch_upload')
                cancelUploadBatch()
                setStage('PRE_TRANSCRIPTION')
              }}
            >
              Cancel
            </Button>
          </div>
        </CrossFade>
      </div>
    </>
  )
}
