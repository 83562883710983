import {css} from '@emotion/react'

import Ellipsis from '../../../../components/Ellipsis'
import useEditableTranscriptName from '../../../../hooks/useEditableTranscriptName'
import {buttonResetCss} from '../../../../styles/buttonReset'
import COLORS from '../../../../styles/colors'
import Tooltip from '../../../../components/Tooltip'

const sharedCss = css`
  text-align: left;
  padding: 0;
  cursor: pointer;
  outline: none;
  width: 128px;
  border-radius: 0.375rem;

  &:hover {
    outline: 1px solid ${COLORS.gray[400]};
    outline-offset: 2px;
  }
`

const inputCss = css`
  cursor: text;
  overflow: visible;
  white-space: normal;
  word-break: break-all;

  &:focus {
    outline: 1px solid ${COLORS.brand.light[500]};
    outline-offset: 2px;
  }
`

const buttonCss = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

interface TranscriptNameProps {
  name: string
  id: string
  refreshData?: () => void
}

export default function TranscriptNameDesktop(props: TranscriptNameProps): React.ReactNode {
  const {name, id, refreshData} = props
  const {isEditing, value, contentRef, getTitleProps, getButtonProps, status} =
    useEditableTranscriptName({
      name,
      id,
      onSuccess: refreshData,
    })

  if (status === 'pending') return <Ellipsis />

  return (
    <div
      data-clarity-mask="True"
      className="w-32"
      onClick={(event) => event.stopPropagation()}
      onKeyDown={(event) => event.stopPropagation()}
    >
      {isEditing ? (
        <div
          css={[sharedCss, inputCss]}
          ref={contentRef}
          role="textbox"
          contentEditable
          suppressContentEditableWarning
          {...getTitleProps()}
        >
          {value}
        </div>
      ) : (
        <Tooltip
          className="break-all"
          target={
            <button
              type="button"
              css={[buttonResetCss, sharedCss, buttonCss]}
              {...getButtonProps()}
            >
              {value}
            </button>
          }
        >
          {value}
        </Tooltip>
      )}
    </div>
  )
}
