import {Fragment, useMemo} from 'react'

import useInView from '../../../hooks/useInView'
import {APITranscriptToken, Stage} from '../../../types/types'
import Ellipsis from '../../../components/Ellipsis'

import TranscriptToken from './TranscriptToken'

interface TranscriptTokenBatchProps {
  tokenMeta: APITranscriptToken[]
  sliceIndex: number
  tokenOffsetIndex: number
  currentTimeMs?: number
  color: string
  onClickToken?: (token: APITranscriptToken) => void
  stage: Stage
  isLast: boolean
  forceTokens: boolean | null
}

/**
 * Only the tokens that are within the viewport or an anchor for a transcript selection are rendered.
 */
export default function TranscriptTokenBatch(props: TranscriptTokenBatchProps): React.ReactNode {
  const {
    sliceIndex,
    tokenMeta,
    tokenOffsetIndex,
    color,
    currentTimeMs,
    onClickToken,
    stage,
    isLast,
    forceTokens,
  } = props
  const {ref, inView} = useInView({initialInView: true, debounceDelay: 300})

  const transcript = useMemo(
    () => tokenMeta.reduce((acc, token) => `${acc}${token.transcript} `, ''),
    [tokenMeta],
  )

  const renderTokens = inView || forceTokens

  return (
    <span ref={ref}>
      {renderTokens
        ? tokenMeta.map((token, i) => (
            // there is no guarantee of token uniqueness, so have to use array index in key
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={`${token.startMs}:${token.transcript}:${i}`}>
              <TranscriptToken
                token={token}
                sliceIndex={sliceIndex}
                tokenIndex={tokenOffsetIndex + i}
                color={color}
                current={
                  currentTimeMs !== undefined &&
                  // don't highlight first word if transcript has not been started yet
                  currentTimeMs > 0 &&
                  currentTimeMs >= token.startMs &&
                  (currentTimeMs < token.startMs + token.durationMs ||
                    // if the time is between tokens keep the leading one highlighted
                    // otherwise the active highlight pops in and out
                    (tokenMeta[i + 1] && currentTimeMs < tokenMeta[i + 1].startMs))
                }
                onClick={onClickToken}
                stage={stage}
              />
              <span data-type="token-space"> </span>
            </Fragment>
          ))
        : transcript}
      {tokenMeta[tokenMeta.length - 1].isPartial && stage === 'TRANSCRIPTION' && isLast && (
        <Ellipsis />
      )}
    </span>
  )
}
