import {createContext} from 'react'

import {Size} from '../types'

export type DisplayGroup =
  | {type: 'button'; fill: boolean; minimal: boolean; size: Size; vertical: boolean}
  | {type: 'control'; disabled: boolean; fill: boolean; size: Size; vertical: boolean}
  | {type: 'input'; disabled: boolean; size: Size; height: number}
  | {type: 'label'; disabled: boolean; size: Size}

export default createContext<DisplayGroup | null>(null)

export interface InputGroupSize {
  margin: number
  height: number
}
/**
 * Gets sizing values for components inside Input Groups.
 *
 * @param group The DisplayGroup to size for, or null.
 * @param margins A mapping of Sizes to pixel margins around the component that the css will be applied to.
 * @returns InputGroupSize for sizing a component for the given Input Group, or null if not applicable to the given DisplayGroup.
 */
export function getInputGroupSize(
  group: DisplayGroup | null,
  margins: Record<Size, number>,
): InputGroupSize | null {
  if (group?.type === 'input') {
    const margin = margins[group.size]
    return {
      margin,
      height: group.height - margin * 2,
    }
  }
  return null
}
