import {Outlet, useNavigate} from 'react-router-dom'
import {NeoProvider} from '@kensho/neo'

import ErrorBoundary from '../components/ErrorBoundary'
import UserProvider from '../providers/UserProvider'
import ThemeProvider from '../components/ui/colors/ThemeProvider'
import ToasterProvider from '../components/ui/toasts/ToasterProvider'
import CookieConsentPopup from '../components/CookieBanner/CookieConsentPopup'
import SiteAnalyticsProvider from '../providers/SiteAnalyticsProvider'

export default function RootPage(): React.ReactNode {
  const navigate = useNavigate()
  return (
    <NeoProvider navigate={navigate}>
      <ThemeProvider theme="light">
        <ToasterProvider position="bottom-right">
          <ErrorBoundary>
            <SiteAnalyticsProvider>
              <CookieConsentPopup />
              <UserProvider>
                <Outlet />
              </UserProvider>
            </SiteAnalyticsProvider>
          </ErrorBoundary>
        </ToasterProvider>
      </ThemeProvider>
    </NeoProvider>
  )
}
