import {css} from '@emotion/react'
import {useContext} from 'react'

import CopyableValue from '../components/CopyableValue'
import UserContext from '../providers/UserContext'
import DictionaryPreference from '../core/account/DictionaryPreference'

import BasePage from './BasePage'

const mainCss = css`
  max-width: 900px;
  width: 100%;
  padding: 27px 27px 0 27px;
  margin: 0 auto;
`

const h1Css = css`
  font-size: 24px;
  color: rgba(0, 0, 0, 0.87);
`

const preferenceCss = css`
  margin: 20px 0;
`

const labelCss = css`
  display: block;
  font-weight: bold;
  line-height: 20px;
  margin: 0;
  margin-bottom: 10px;
`

const inputCss = css`
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding: 4px 8px;
  height: 36px;
  width: 370px;
  max-width: 100%;
  margin-top: 4px;
  display: block;
  font-weight: normal;

  &:invalid {
    border-color: red;
  }
`

export default function AccountPage(): React.ReactNode {
  const {user} = useContext(UserContext)

  if (!user) throw new Error('Unauthenticated')

  return (
    <BasePage>
      <main css={mainCss}>
        <h1 css={h1Css}>Scribe Account Details</h1>
        {user.email && (
          <div css={preferenceCss}>
            <label css={labelCss} htmlFor="email-input">
              Email Address
              <input
                id="email-input"
                css={inputCss}
                type="text"
                disabled
                value={user.email}
                data-clarity-mask="True"
              />
            </label>
          </div>
        )}
        <div css={preferenceCss} data-clarity-mask="True">
          <CopyableValue
            css={preferenceCss}
            value={user.refreshToken}
            label="Scribe Refresh Token"
            description="Used to generate new temporary Access Tokens"
            data-clarity-mask="True"
          />
        </div>
        {user.token && (
          <div css={preferenceCss} data-clarity-mask="True">
            <CopyableValue
              value={user.token}
              label="Scribe Access Token"
              description="Used to programmatically access the API"
            />
          </div>
        )}
        <div css={preferenceCss}>
          <span css={labelCss}>Dictionary</span>
          <DictionaryPreference />
        </div>
      </main>
    </BasePage>
  )
}
