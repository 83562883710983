import {css} from '@emotion/react'
import {IconCopy} from '@kensho/icons'

import copyText from '../utils/copyText'

interface CopyableValueProps {
  className?: string
  value: string
  label?: string
  description?: React.ReactNode
}

const copyableValueCss = css`
  margin: 4px 0;
`

const labelCss = css`
  font-weight: bold;
  line-height: 20px;
  margin: 0;
`

const valueCss = css`
  color: #545454;
  background: rgba(239, 239, 239, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding: 4px 8px;
  width: 370px;
  max-width: 100%;
  margin: 4px 0 0 0;
  word-break: break-all;
  max-height: 5em;
  overflow-y: auto;
`

const valueRowCss = css`
  display: flex;
  align-items: center;
`

const iconCopyCss = css`
  margin-left: 12px;
  color: #aaa;
  cursor: pointer;
  transition: all 0.2s;
  padding-top: 5px;

  &:hover {
    color: #000;
  }
`

const descriptionCss = css`
  margin: 4px 0 0 8px;
`

export default function CopyableValue(props: CopyableValueProps): React.ReactNode {
  const {className, value, label, description} = props

  return (
    <div className={className} css={copyableValueCss}>
      {label && <p css={labelCss}>{label}</p>}
      <div css={valueRowCss}>
        <p css={valueCss}>{value}</p>
        <IconCopy css={iconCopyCss} size={26} onClick={() => copyText(value)} />
      </div>
      {description && <div css={descriptionCss}>{description}</div>}
    </div>
  )
}
