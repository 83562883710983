import * as React from 'react'

import Popover from '../Popover'
import Menu from '../ui/menu/Menu'
import {useRegisterMultiClickOutsideElement} from '../../providers/MultiClickOutsideContext'
import {SiblingMenuProvider} from '../../providers/SiblingMenuContext'

import popoverPaddingResetCss from './shared'

interface CascadingMenuProps {
  children: React.ReactNode
  isOpen: boolean
  target: React.ReactNode
  className?: string
}

export default function CascadingMenu({
  children,
  isOpen,
  target,
  className,
}: CascadingMenuProps): React.ReactNode {
  const clickOutsideRef = useRegisterMultiClickOutsideElement<HTMLUListElement>()

  return (
    <Popover
      css={popoverPaddingResetCss}
      isOpen={isOpen}
      target={target}
      popperPlacement="bottom-start"
    >
      <SiblingMenuProvider>
        <Menu className={className} ref={clickOutsideRef}>
          {children}
        </Menu>
      </SiblingMenuProvider>
    </Popover>
  )
}
