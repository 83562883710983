import {css, SerializedStyles} from '@emotion/react'
import {forwardRef, useContext} from 'react'

import getBackgroundColor, {BackgroundColorOptions} from '../colors/getBackgroundColor'
import getTextColor from '../colors/getTextColor'
import {useTheme} from '../colors/ThemeProvider'
import {IconComponent, Intent, Size} from '../types'
import assertNever from '../utils/assertNever'
import {BORDER, FOCUS_OUTLINE} from '../constants/theme'
import {FOCUS_OUTLINE_OFFSET} from '../constants/values'

import ClearGroup from './ClearGroup'
import DisplayGroupContext, {
  DisplayGroup,
  getInputGroupSize,
  InputGroupSize,
} from './DisplayGroupContext'

const PADDING: Record<Size, number> = {
  small: 8,
  medium: 10,
  large: 14,
}

const HEIGHT: Record<Size, number> = {
  small: 24,
  medium: 30,
  large: 40,
}

const FONT_SIZE: Record<Size, number> = {
  small: 14,
  medium: 14,
  large: 16,
}

const ICON_MARGIN: Record<Size, number> = {
  small: 8,
  medium: 8,
  large: 10,
}

const INPUT_GROUP_MARGIN: Record<Size, number> = {
  small: 2,
  medium: 4,
  large: 4,
}

const LINE_HEIGHT_OFFSET = 4
const BORDER_WIDTH = 1

function getMinimal(minimal: boolean, context: DisplayGroup | null): boolean {
  if (context === null) {
    return minimal
  }
  switch (context.type) {
    case 'button':
      return context.minimal
    case 'input':
      // Buttons in input groups are always minimal
      return true
    case 'control':
    case 'label':
      return minimal
    default:
      return assertNever(context)
  }
}

function getFill(fill: boolean, context: DisplayGroup | null): boolean {
  if (context === null) {
    return fill
  }
  switch (context.type) {
    case 'button':
    case 'control':
      return context.fill || fill
    case 'input':
      // fill buttons in input groups don't make sense.
      return false
    case 'label':
      return fill
    default:
      return assertNever(context)
  }
}

function getSize(size: Size, context: DisplayGroup | null): Size {
  if (context === null) {
    return size
  }
  switch (context.type) {
    case 'button':
    case 'input':
    case 'control':
      // For text sizing, actual sizing is controlled by getInputGroupSizeCss.
      return context.size
    case 'label':
      return size
    default:
      return assertNever(context)
  }
}

function getDisabled(disabled: boolean, context: DisplayGroup | null): boolean {
  if (context === null) {
    return disabled
  }
  switch (context.type) {
    case 'button':
      return disabled
    case 'control':
    case 'input':
    case 'label':
      return context.disabled || disabled
    default:
      return assertNever(context)
  }
}

function getPadding(hasChildren: boolean, size: Size, inInputGroup: boolean): string {
  if (!hasChildren) return '0'
  const basePadding = PADDING[size]
  const adjustedPadding = basePadding - BORDER_WIDTH - (inInputGroup ? INPUT_GROUP_MARGIN[size] : 0)
  return `0 ${adjustedPadding}px`
}

function getWidth(
  hasChildren: boolean,
  fill: boolean,
  size: Size,
  groupSize: InputGroupSize | null,
): string {
  if (fill) return '100%'
  if (hasChildren) return 'auto'
  if (groupSize) return `${groupSize.height}px`
  return `${HEIGHT[size]}px`
}

function getHeight(size: Size, groupSize: InputGroupSize | null): string {
  return `${groupSize?.height || HEIGHT[size]}px`
}

const cssHorizontalBorder = css`
  :not(:last-child) {
    border-right: none;
  }
`

const cssVerticalBorder = css`
  :not(:last-child) {
    border-bottom: none;
  }
`

export function getGroupBorderCss(context: DisplayGroup | null): SerializedStyles | null {
  if (context === null) return null
  switch (context.type) {
    case 'button':
    case 'control':
      return context.vertical ? cssVerticalBorder : cssHorizontalBorder
    default:
      return null
  }
}

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Whether the button is active (pressed).
   *
   * @default false
   */
  active?: boolean

  /** Contents of the button. Typically a text label describing an action. */
  children?: React.ReactNode

  /** Space-separated list of classes to pass to the underlying element. */
  className?: string

  /**
   * Whether to disable interactivity.
   *
   * @default false
   */
  disabled?: boolean

  /**
   * Whether to expand the button to fill available horizontal space.
   *
   * @default false
   */
  fill?: boolean

  /** Icon to render to the left of the button's contents. */
  icon?: IconComponent

  /** Visual intent of the button. */
  intent?: Intent

  /**
   * Whether to subtly blend the button into the background.
   *
   * @default false
   */
  minimal?: boolean

  /** Icon to render to the right of the button's contents. */
  rightIcon?: IconComponent

  /**
   * What size to render the button.
   *
   * @default 'medium'
   */
  size?: Size

  /**
   * Whether this button is a toggle button. The pressed state must be controlled
   * using the `active` prop. This prop ensures accessibility around toggle buttons.
   *
   * @default false
   */
  toggle?: boolean

  /**
   * Type of the underlying `<button>`.
   *
   * @default 'button'
   */
  type?: 'button' | 'submit'
}

/**
 * Triggers an action when pressed. Can be used as a toggle. Text content can be rendered alongside icons.
 *
 * @see ButtonGroup to group together multiple buttons.
 * @see ControlGroup to group a button with related controls.
 */
function Button(props: ButtonProps, ref: React.Ref<HTMLButtonElement>): React.ReactNode {
  const {
    active = false,
    children,
    disabled: propDisabled = false,
    fill: propFill = false,
    icon: Icon,
    intent,
    minimal: propMinimal = false,
    rightIcon: RightIcon,
    size: propSize = 'medium',
    toggle = false,
    type = 'button',
    ...rest
  } = props
  const group = useContext(DisplayGroupContext)
  const theme = useTheme()
  const disabled = getDisabled(propDisabled, group)
  const fill = getFill(propFill, group)
  const minimal = getMinimal(propMinimal, group)
  const size = getSize(propSize, group)
  const cssGroupBorder = getGroupBorderCss(group)
  const groupSize = getInputGroupSize(group, INPUT_GROUP_MARGIN)
  const hasChildren = children != null
  const hasBorder = !intent && !minimal

  const buttonOptions: BackgroundColorOptions = {
    depth: active ? -2 : 0,
    disabled,
    intent,
    minimal,
    theme,
  }
  const textColor = getTextColor(buttonOptions)

  const cssButton = css`
    width: ${getWidth(hasChildren, fill, size, groupSize)};
    height: ${getHeight(size, groupSize)};
    position: relative;
    display: ${fill ? 'flex' : 'inline-flex'};
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: ${BORDER_WIDTH}px solid ${hasBorder ? BORDER[theme] : 'transparent'};
    padding: ${getPadding(hasChildren, size, Boolean(groupSize))};
    margin: ${groupSize?.margin || 0}px;
    cursor: pointer;
    user-select: none;
    font-family: inherit;
    font-size: ${FONT_SIZE[size]}px;
    line-height: ${FONT_SIZE[size] + LINE_HEIGHT_OFFSET}px;
    color: ${textColor};
    background: ${getBackgroundColor(buttonOptions)};
    outline-offset: ${FOCUS_OUTLINE_OFFSET}px;

    :hover:enabled {
      background: ${getBackgroundColor({...buttonOptions, depth: active ? -3 : -1})};
      :active {
        background: ${getBackgroundColor({...buttonOptions, depth: -3})};
      }
    }
    :active:enabled {
      background: ${getBackgroundColor({...buttonOptions, depth: -2})};
    }
    :disabled {
      cursor: not-allowed;
    }
    :focus-visible {
      z-index: 5;
      outline: ${FOCUS_OUTLINE[theme]};
    }
  `

  const cssIcon = css`
    color: ${textColor};
    margin-right: ${hasChildren ? ICON_MARGIN[size] : 0}px;
  `

  const cssRightIcon = css`
    color: ${textColor};
    margin-left: ${hasChildren ? ICON_MARGIN[size] : 0}px;
  `

  return (
    <ClearGroup>
      <button
        css={[cssButton, cssGroupBorder]}
        disabled={disabled}
        // eslint-disable-next-line react/button-has-type
        type={type}
        ref={ref}
        aria-pressed={toggle ? active : undefined}
        {...rest}
      >
        {Icon != null && <Icon css={cssIcon} size={20} />}
        {hasChildren && <span>{children}</span>}
        {RightIcon != null && <RightIcon css={cssRightIcon} size={20} />}
      </button>
    </ClearGroup>
  )
}

export default forwardRef(Button)
