import {Dialog, Button, Tooltip, Icon, AlertDialog, ButtonLink} from '@kensho/neo'
import {useLogger} from '@kensho/lumberjack'
import {usePrevious} from '@kensho/tacklebox'
import {useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {EMAILS} from '../constants'
import useLoginURL, {LoginPaths} from '../hooks/useLoginURL'
import UserContext from '../providers/UserContext'
import {ScribeError} from '../types/types'
import ExportLocalTranscriptButton from '../core/transcription/actions/ExportLocalTranscriptButton'

import Link from './Link'

interface ScribeErrorDisplay extends ScribeError {
  content: React.ReactNode
  actions?: React.ReactNode
  dismissable?: boolean
}

interface ErrorDialogProps {
  isOpen: boolean
  error?: ScribeError
  onClose: () => void
}

const faqLink = (
  <span className="font-semibold">
    <Link to="https://docs.kensho.com/scribe/v2/faq">FAQ section</Link>
  </span>
)

const emailLink = (
  <span className="font-semibold">
    <Link to={EMAILS.SUPPORT} />
  </span>
)

function getErrorDisplay(
  onClose: () => void,
  loginPaths: LoginPaths,
  error?: ScribeError,
): ScribeErrorDisplay {
  switch (error?.type) {
    case 'badRequest':
      return {
        ...error,
        title: 'Bad Request',
        content: (
          <div className="flex flex-col gap-2">
            <p>There was a problem with the request.</p>
            <p>
              {error.detail ? (
                <>
                  <span className="font-bold">Reason: </span> {error?.detail}
                </>
              ) : (
                <>Check the {faqLink} for additional troubleshooting information.</>
              )}
            </p>
          </div>
        ),
      }

    case 'multipleFiles':
      return {
        ...error,
        title: 'Please Submit One File at a Time',
        content: (
          <>
            <p>
              The Scribe Interface only supports selecting one file upload at a time. If it is a
              priority for you to upload multiple files at once, we recommend using the Scribe Batch
              API or reaching out to {emailLink} to discuss how we can better meet your
              transcription needs.
            </p>
          </>
        ),
      }

    case 'invalidFile':
      return {
        ...error,
        title: 'Unsupported/Invalid File Type',
        content: (
          <>
            <p>
              Kensho Scribe currently supports MP3, WAV, and MP4 files. If you have specific file
              types you want us to support, please send feedback to {emailLink}.
            </p>
          </>
        ),
      }

    case 'corruptedFile':
      return {
        ...error,
        title: 'Unrecognizable or Corrupted File',
        content: (
          <>
            <p>
              This file is either corrupted or not supported by this browser. If you believe the
              file is not corrupted you can try converting it into another of our supported file
              formats (MP3, WAV, and MP4) and reuploading. If the error continues you can reach out
              to {emailLink}.
            </p>
          </>
        ),
      }

    case 'unauthenticated':
      return {
        ...error,
        title: 'Session Expired',
        content: (
          <p>
            You are unauthenticated or your session may have expired. Please{' '}
            <span className="font-bold hover:opacity-75">
              <Link to={loginPaths.URL} routing="server">
                sign in
              </Link>
            </span>{' '}
            or{' '}
            <span className="font-bold hover:opacity-75">
              <Link to={loginPaths.MARKETPLACE_IDP_URL} routing="server">
                sign in with S&P Marketplace
              </Link>
            </span>{' '}
            again.
          </p>
        ),
      }

    case 'usageLimit':
      return {
        ...error,
        title: 'You have exceeded your usage limit',
        content: (
          <>
            <p>
              You have exceeded your usage limit for transcribing. Please reach out to {emailLink}{' '}
              if you would like to increase or upgrade your plan.
            </p>
          </>
        ),
      }

    case 'rateLimit':
      return {
        ...error,
        title: 'You have exceeded your rate limit',
        content: (
          <>
            <p>
              You have exceeded the max amount of audio you can transcribe in a single hour. Try
              again later or see the {faqLink} for more information. If this continues or you think
              this message is in error, please contact {emailLink} for assistance.
            </p>
          </>
        ),
      }

    case 'fileTooLarge':
      return {
        ...error,
        title: 'File Too Large',
        content: (
          <>
            <p>
              Upload failed: file exceeds 1GB limit. Please try again with a smaller file or see the{' '}
              {faqLink} for more information. If this continues or you think this message is in
              error, please contact {emailLink} for assistance.
            </p>
          </>
        ),
      }

    case 'transcriptNotFound':
      return {
        ...error,
        title: 'Transcript Not Found',
        dismissable: false,
        content: (
          <div className="flex flex-col gap-2">
            <p>This transcript either doesn‘t exist or has expired.</p>
            <p>
              If you believe this is in error you can look for common troubleshooting measures in
              the {faqLink} or email {emailLink}.
            </p>
          </div>
        ),
        actions: (
          // force a reload to clear state
          <ButtonLink forceServer href="/transcription">
            Start a New Transcription
          </ButtonLink>
        ),
      }

    case 'transcriptRequestTimedOut':
      return {
        ...error,
        title: 'Request timed out',
        dismissable: false,
        content: (
          <>
            <p>
              Try again after refreshing the page. If the issue persists you can look for common
              troubleshooting measures in the {faqLink} or email {emailLink}.
            </p>
          </>
        ),
        actions: (
          // force a reload to clear state
          <ButtonLink forceServer href={window.location.pathname} intent="primary">
            Reload page
          </ButtonLink>
        ),
      }

    case 'unsavedTranscript':
      return {
        ...error,
        title: 'Leave page and discard this transcript?',
        content: (
          <div className="flex flex-col gap-2">
            <p>Are you sure you want to leave this page and discard this transcript?</p>
            <p>
              Scribe does not save real time transcriptions, so be sure to download your transcript
              text before closing your session, or it will be lost permanently.
            </p>
          </div>
        ),
        actions: (
          <div className="flex gap-4">
            <Button onClick={onClose}>Cancel & Stay on Page</Button>
            {/* force a reload to clear state */}
            <ButtonLink forceServer href="/transcription" intent="danger">
              Leave & Discard Transcript
            </ButtonLink>
          </div>
        ),
      }

    case 'audioInputUnsupported':
      return {
        ...error,
        title: 'Audio Input Unsupported',
        content: (
          <>
            <p>
              Failed to record from selected microphone. Try using a different audio input or see
              our {faqLink} for common troubleshooting measures.
            </p>
          </>
        ),
      }

    case 'realtimeError':
      return {
        ...error,
        title: 'Error during recording',
        content: (
          <>
            <p>
              An error was encountered in the process of transcribing realtime audio and forced the
              transcription to close. You can still view the transcription and recording up until
              that point.
            </p>
          </>
        ),
      }

    case 'saveTranscriptConflict':
      return {
        ...error,
        title: 'Changes not saved',
        dismissable: false,
        content: (
          <p>
            Changes to the transcript could not be applied, a newer version is available. Please
            reload the page.
          </p>
        ),
        actions: (
          <>
            <ExportLocalTranscriptButton />
            {/* force a reload to clear state */}
            <ButtonLink forceServer href={window.location.pathname} intent="primary">
              Reload page
            </ButtonLink>
          </>
        ),
      }

    case 'saveTranscriptError':
      return {
        ...error,
        title: 'Changes not saved',
        dismissable: false,
        content: <p>Could not save transcript. Please reload the page.</p>,
        actions: (
          <>
            <ExportLocalTranscriptButton />
            {/* force a reload to clear state */}
            <ButtonLink forceServer href={window.location.pathname} intent="primary">
              Reload page
            </ButtonLink>
          </>
        ),
      }

    case 'generic':
      return {
        ...error,
        content: (
          <>
            <p>
              The Scribe Interface has encountered an unknown error. Try again after refreshing the
              page. If the issue persists you can look for common troubleshooting measures in the{' '}
              {faqLink} or email {emailLink}.
            </p>
          </>
        ),
      }
    case 'unknown':
    default:
      return {
        ...error,
        type: 'unknown',
        title: 'Unknown Error',
        content: (
          <>
            <p>
              The Scribe Interface has encountered an unknown error. Try again after refreshing the
              page. If the issue persists you can look for common troubleshooting measures in the{' '}
              {faqLink} or email {emailLink}.
            </p>
          </>
        ),
      }
  }
}

export default function ErrorDialog(props: ErrorDialogProps): React.ReactNode {
  const {isOpen, error, onClose} = props
  const {transcriptId} = useParams()
  const prevError = usePrevious(error)
  const log = useLogger()
  const {user} = useContext(UserContext)
  const loginPaths = useLoginURL()
  const [errorDisplay, setErrorDisplay] = useState(getErrorDisplay(onClose, loginPaths, error))
  const [showDetail, setShowDetail] = useState(true)

  useEffect(() => {
    if (error && error !== prevError) {
      log.error(
        `errorDialog:${error.type}:${error.status}:${error.title}`,
        /* @ts-ignore ts(2345) */
        {
          ...error,
          transcriptId,
          user: user?.email || 'unauthenticated',
        },
      )
    }
  }, [error, prevError, log, user, transcriptId])

  useEffect(() => {
    // only update error when opened to prevent flash of default content during hide transition
    if (isOpen) setErrorDisplay(getErrorDisplay(onClose, loginPaths, error))
  }, [isOpen, error, onClose, loginPaths])

  const dialogContent = (
    <section data-testid="error-dialog">
      <div className="mb-6">{errorDisplay.content}</div>
      {error?.detail && (
        <div className="mb-6">
          <div
            className="mr-px flex cursor-pointer items-center justify-between"
            onClick={() => setShowDetail((prev) => !prev)}
            onKeyDown={({key}) => key === 'Enter' && setShowDetail((prev) => !prev)}
            role="button"
            tabIndex={0}
          >
            Error Detail
            {showDetail ? (
              <Tooltip content="Collapse">
                <Icon icon="MinusCircleIcon" />
              </Tooltip>
            ) : (
              <Tooltip content="Expand">
                <Icon icon="PlusCircleIcon" />
              </Tooltip>
            )}
          </div>
          {showDetail && (
            <div className="mt-4 max-h-40 overflow-auto bg-gray-200 p-3 font-mono text-xs">
              <p>User ID: {user?.email}</p>
              {transcriptId && <p>Transcript ID: {transcriptId}</p>}
              {error.status && <p>Status: {error.status}</p>}
              <p>Detail: {error.detail}</p>
            </div>
          )}
        </div>
      )}

      <div className="flex justify-end gap-4">
        {errorDisplay.actions && <>{errorDisplay.actions}</>}

        {!errorDisplay.actions && errorDisplay.dismissable !== false && (
          <Button onClick={onClose}>Dismiss</Button>
        )}
      </div>
    </section>
  )

  return errorDisplay.dismissable === false ? (
    <AlertDialog isOpen={isOpen} title={errorDisplay.title} backdrop="dark">
      {dialogContent}
    </AlertDialog>
  ) : (
    <Dialog isOpen={isOpen} title={errorDisplay.title} onClose={onClose} backdrop="dark">
      {dialogContent}
    </Dialog>
  )
}
