import {createContext, useContext, useEffect, useId, useMemo, useState} from 'react'

type State = Record<string, () => void>

const SiblingMenuContext = createContext<
  {state: State; setState: React.Dispatch<React.SetStateAction<State>>} | undefined
>(undefined)

export const SiblingMenuProvider = ({children}: {children: React.ReactNode}): React.ReactNode => {
  const [state, setState] = useState<State>({})
  const value = useMemo(() => ({state, setState}), [state, setState])
  return <SiblingMenuContext.Provider value={value}>{children}</SiblingMenuContext.Provider>
}
/**
 * Registers a callback with the context and returns a function that will invoke all registered callbacks.
 * Intended for uses by cascading submenus to close sibling menus before opening itself.
 */
export const useSiblingMenuContext = (
  callback: () => void,
): {
  closeAllMenus: () => void
} => {
  const context = useContext(SiblingMenuContext)
  const id = useId()
  if (context === undefined) {
    throw new Error('useSiblingContext must be used within a SiblingProvider')
  }
  const {state, setState} = context

  useEffect(() => {
    setState((prev) => ({...prev, [id]: callback}))
    return () => {
      setState((prev) => {
        const {[id]: _, ...rest} = prev
        return rest
      })
    }
  }, [callback, id, setState])

  const closeAllMenus = (): void => {
    Object.values(state).forEach((registeredCallback) => registeredCallback())
  }

  return {closeAllMenus}
}
