import {css} from '@emotion/react'

import {TranscriptMetadata} from '../../../types/types'

type JobStatus = TranscriptMetadata['status']

type JobStatusWithoutSubmitted = Exclude<JobStatus, 'submitted'>

const JOB_STATUS_COLOR: Record<JobStatusWithoutSubmitted, string> = {
  failed: '#C61936',
  in_progress: '#007A99',
  complete: '#01822C',
}

const JOB_STATUS_MESSAGE: Record<JobStatusWithoutSubmitted, string> = {
  failed: 'Error',
  in_progress: 'Processing',
  complete: 'Complete',
}

const projectStatusCss = css`
  display: flex;
  align-items: center;
`

const circleCss = css`
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin: 4px 8px 4px 4px;
`

const animatedCircleCss = css`
  animation: pulse 1s infinite;

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.6;
    }
  }
`

interface JobStatusProps {
  status: JobStatus
  textOnly?: boolean
}

export default function JobStatus(props: JobStatusProps): React.ReactNode {
  const {status, textOnly} = props
  const replaceSubmitted = status === 'submitted' ? 'in_progress' : status
  const color = JOB_STATUS_COLOR[replaceSubmitted]

  return (
    <div css={projectStatusCss}>
      {textOnly ? (
        <span style={{color}}>Transcription {JOB_STATUS_MESSAGE[replaceSubmitted]}</span>
      ) : (
        <>
          <div
            css={[circleCss, replaceSubmitted === 'in_progress' && animatedCircleCss]}
            style={{background: color}}
          />
          <span style={{color}}>{JOB_STATUS_MESSAGE[replaceSubmitted]}</span>
        </>
      )}
    </div>
  )
}
