import {Button} from '@kensho/neo'
import {ReactNode, useContext} from 'react'

import AudioInputDeviceSelect from '../../../components/AudioInputDeviceSelect'
import {
  RealtimeStatus,
  RealtimeTranscriptionConfiguration,
  TranscriptionConfiguration,
} from '../../../types/types'
import SiteAnalyticsContext from '../../../providers/SiteAnalyticsContext'

import PartialTranscripts from './PartialTranscripts'
import TranscriptName from './TranscriptName'
import {FormState} from './FormState'

interface RealtimeConfigFormProps {
  transcriptionConfiguration: RealtimeTranscriptionConfiguration
  setTranscriptionConfiguration: React.Dispatch<React.SetStateAction<TranscriptionConfiguration>>
  audioInputDeviceId?: string
  onAudioInputDeviceChange: (nextAudioDeviceInfoId?: string) => void
  setAudioInputDeviceReady: React.Dispatch<React.SetStateAction<boolean>>
  resetAudioAndTranscript: () => void
  setRealtimeStatus: React.Dispatch<React.SetStateAction<RealtimeStatus>>
  realtimeStatus: RealtimeStatus
  formState: FormState
  setFormState: React.Dispatch<React.SetStateAction<FormState>>
}

export default function RealtimeConfigForm(props: RealtimeConfigFormProps): ReactNode {
  const {
    resetAudioAndTranscript,
    transcriptionConfiguration,
    setTranscriptionConfiguration,
    setRealtimeStatus,
    realtimeStatus,
    audioInputDeviceId,
    onAudioInputDeviceChange,
    setAudioInputDeviceReady,
    formState,
    setFormState,
  } = props
  const analytics = useContext(SiteAnalyticsContext)

  return (
    <div className="flex flex-col gap-10">
      <div className="text-lg font-semibold">Start a new recording</div>
      <TranscriptName
        error={formState.errors.transcriptName}
        setFormState={setFormState}
        transcriptName={formState.transcriptName}
      />
      <AudioInputDeviceSelect
        value={audioInputDeviceId}
        onChange={onAudioInputDeviceChange}
        setAudioInputDeviceReady={setAudioInputDeviceReady}
        formError={formState.errors.audioInputDevice}
        setFormState={setFormState}
      />
      <PartialTranscripts
        transcriptionConfiguration={transcriptionConfiguration}
        setTranscriptionConfiguration={setTranscriptionConfiguration}
      />
      <div className="-my-5 flex justify-end gap-4">
        <Button
          onClick={() => {
            analytics.sendEvent('cancel_batch')
            resetAudioAndTranscript()
          }}
        >
          Cancel
        </Button>
        <Button
          intent="primary"
          disabled={
            realtimeStatus !== 'unstarted' || Object.values(formState.errors).some((error) => error)
          }
          onClick={() => {
            const newTranscriptionConfiguration = {
              ...transcriptionConfiguration,
              name: formState.transcriptName,
              hotwords: formState.hotwords,
            }
            setTranscriptionConfiguration(newTranscriptionConfiguration)
            analytics.sendEvent('start_realtime_transcription')
            setRealtimeStatus('starting')
          }}
        >
          Start Recording
        </Button>
      </div>
    </div>
  )
}
