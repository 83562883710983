import {css} from '@emotion/react'
import {IconDocumentNew} from '@kensho/icons'
import {DropzoneOptions, useDropzone} from 'react-dropzone'

import {VALID_AUDIO_FILE_EXTS} from './constants'

const fileUploadOverlayCss = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.54);
`
const documentNewCss = css`
  margin-bottom: 10px;
`
interface FileUploadProps {
  handleFileDrop: DropzoneOptions['onDrop']
  children: (dropzoneState: ReturnType<typeof useDropzone>) => JSX.Element
  enableDragState?: boolean
  disabled?: boolean
}

export default function FileUpload({
  children,
  handleFileDrop,
  disabled = false,
  enableDragState = true,
}: FileUploadProps): React.ReactNode {
  const dropzoneState = useDropzone({
    onDrop: handleFileDrop,
    noClick: true,
    noKeyboard: true,
    // Specify both mpeg and mp3 MIME types in order to support both Chrome and Firefox
    accept: {
      'audio/*': VALID_AUDIO_FILE_EXTS,
      'video/mp4': [],
    },
    maxSize: 1000000000, // 1 GB
    useFsAccessApi: false,
    disabled,
  })
  const {getRootProps, getInputProps, isDragActive} = dropzoneState
  return (
    <div
      css={css`
        position: relative;
      `}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isDragActive && enableDragState && (
        <div css={fileUploadOverlayCss}>
          <IconDocumentNew size={30} css={documentNewCss} />
          Drop file to begin a new transcript
        </div>
      )}
      <div
        css={css`
          border: ${isDragActive && enableDragState && `1px dashed rgba(0, 0, 0, 0.54)`};
        `}
      >
        <div
          css={css`
            opacity: ${isDragActive && enableDragState ? 0 : 1};
          `}
        >
          {children(dropzoneState)}
        </div>
      </div>
    </div>
  )
}
